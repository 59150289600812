import styled from 'styled-components'
import S from './Slider'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 600px;

  @media (max-width: 1280px) {
    max-width: 400px;
    min-height: 370px;
    width: 100%;
    align-items: stretch;
  }

  @media (max-width: 450px) {
    max-width: 300px;
  }

  padding: 32px 0;

  @media (max-width: 880px) {
    align-items: stretch;
    margin-top: 64px;
  }

  @media (max-width: 500px) {
    margin-top: 32px;
  }

  .carousel__dot {
    width: 16px;
    height: 16px;
    margin: 10px 16px 0 0;
    padding: 0;
    border: 2px solid white;
    background-color: transparent;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.5);
  }

  .carousel__dot--selected {
    box-shadow: none;
    background-color: white;
  }
`

const Slider = () => {
  return (
    <Wrapper>
      <S />
    </Wrapper>
  )
}

export default Slider
