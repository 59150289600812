import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents'
import { useTranslation } from 'react-i18next'

const Wrapper = styled(PrimaryButtonFlexable48)`
  border-radius: 8px;
  width: 304px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  letter-spacing: 0.5px;
  font: var(--typography-16-20-semibold);

  @media (max-width: 1280px) {
    width: 256px;
    height: 48px;
    font-size: 14px;
  }

  @media (max-width: 500px) {
    width: 240px;
  }

  :disabled {
    cursor: auto;
  }
`

const Button = props => {
  const { disabled } = props

  const { t } = useTranslation()

  const history = useHistory()

  const redirect = () => {
    history.push('/acc')
  }

  return (
    <Wrapper disabled={disabled} onClick={redirect}>
      {t('confirmMail.login')}
    </Wrapper>
  )
}

export default Button
