import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
// import S from 'components/Common/Slider'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: var(--typography-normal);
  max-width: 600px;
  word-break: break-word;

  @media (max-width: 880px) {
    max-width: 440px;
  }
`

const Title = styled.div`
  font: var(--typography-44-62-bold);
  color: #fff;
  margin: 16px 0;

  @media (max-width: 880px) {
    font-size: 30px;
    line-height: 42px;
    margin: 8px 0;
  }

  @media (max-width: 375px) {
    font-size: 22px;
    line-height: 30px;
  }
`
const Text = styled.div`
  font: var(--typography-24-34-medium);
  color: #a0a1ad;
  margin: 16px 0;

  @media (max-width: 880px) {
    font-size: 16px;
    line-height: 22px;
    margin: 8px 0;
  }

  @media (max-width: 375px) {
    font-size: 13px;
    line-height: 18px;
  }
`
const Block = styled.div`
  text-align: left;
  margin-right: 50px;

  @media (max-width: 880px) {
    margin-top: 64px;
    margin-right: 0;
  }

  @media (max-width: 375px) {
    text-align: center;
    margin-top: 32px;
  }
`

const Slider = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Block>
        <Title>{t('login.slider.slide1.title')}</Title>
        <Text>{t('login.slider.slide1.text')}</Text>
      </Block>
    </Wrapper>
  )
}

export default Slider
