import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { lineClamp } from 'components/Common/GlobalStyles'
import { menuItemStyles } from '../../_styles'

export const IconWrapper = styled.div`
  display: flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: 24px;
  height: 24px;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const Text = styled.span`
  flex: 1;
  user-select: none;
  ${lineClamp()};
`

export const MenuItemStyles = css<{ $active?: boolean; $disabled?: boolean }>`
  box-sizing: border-box;
  display: flex;
  padding: 7px 16px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  gap: 16px;
  width: 100%;
  max-width: calc(var(--sidebar-width) - 16px);
  ${props =>
    menuItemStyles({ active: props.$active, disabled: props.$disabled })};

  font: var(--typography-13-20-medium);
`

export const LinkMenuItem = styled(Link)<{
  $active?: boolean
  $disabled?: boolean
}>`
  ${MenuItemStyles};
  text-decoration: none;
`

export const LinkFakeItem = styled.div<{
  $disabled?: boolean
}>`
  ${MenuItemStyles};
`

export const ButtonMenuItem = styled.button<{
  $active?: boolean
  $disabled?: boolean
}>`
  all: unset;
  ${MenuItemStyles};
`

export const CollapseButton = styled.div<{ $opened: boolean }>`
  margin-right: -8px;
  width: 20px;
  height: 20px;

  svg {
    transform: rotate(${props => (props.$opened ? 0 : 180)}deg);
  }
`

export const TooltipText = styled.span`
  color: var(--grey-10, #e7e7ea);
  font: var(--typography-13-20-medium);
  position: relative;
  white-space: nowrap;
`

export const MenuItemTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
