const Success = () => (
  <svg
    width='68'
    height='85'
    viewBox='0 0 68 85'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M1.89308 26.8807H7.09667V25.7442C7.09667 -8.58141 60.6603 -8.58141 60.6603 25.7442V26.8807H65.8639C66.9141 26.8807 67.7647 27.7307 67.7647 28.7802V73.4638C67.7647 79.7998 62.5611 85 56.2201 85H11.5924C5.21704 85 0 79.7872 0 73.416V28.7802C0 27.7307 0.850634 26.8807 1.89652 26.8807H1.89308ZM3.79393 68.8145H13.9798V64.4082H3.79393V68.8145ZM15.8763 72.6092H3.79393V73.4126C3.79393 77.6973 7.30054 81.2014 11.5884 81.2014H56.216C60.4822 81.2014 63.9629 77.7233 63.9629 73.4602V30.6718H3.79426V36.007H15.8766C16.9269 36.007 17.7775 36.8571 17.7775 37.9065V70.7056C17.7775 71.7551 16.9269 72.6051 15.8766 72.6051L15.8763 72.6092ZM3.79393 60.6133H13.9798V56.2071H3.79393V60.6133ZM3.79393 52.4125H13.9798V48.0063H3.79393V52.4125ZM10.894 26.8811H16.588V25.7446C16.588 3.91772 51.1642 3.91772 51.1642 25.7446V26.8811H56.8582V25.7446C56.8582 -3.58955 10.8896 -3.58955 10.8896 25.7446V26.8811H10.894ZM20.3855 26.8811H47.3667V25.7446C47.3667 8.90925 20.3855 8.90925 20.3855 25.7446V26.8811ZM13.9755 39.8104H3.78959V44.2166H13.9755V39.8104Z'
      fill='#3B42A5'
    />
    <path
      d='M48.8232 46.0007C48.2736 46.0194 47.7521 46.2379 47.3708 46.6124C43.324 50.4462 40.2342 53.6479 36.478 57.273L32.4774 54.0773C32.0499 53.7356 31.4937 53.5687 30.9342 53.6123C30.3747 53.6576 29.8581 53.9119 29.4967 54.3176C29.1353 54.7248 28.9587 55.2491 29.0082 55.7781C29.0577 56.307 29.3267 56.7955 29.7575 57.1356L35.2501 61.5296C36.0886 62.1974 37.3264 62.149 38.1021 61.4172C42.6526 57.1059 45.8924 53.6622 50.355 49.4336C50.9821 48.861 51.1736 47.9825 50.8336 47.2226C50.4952 46.4611 49.6964 45.9774 48.8234 46.0008L48.8232 46.0007Z'
      fill='#00A042'
    />
  </svg>
)

export default Success
