import styled from 'styled-components'
import Main from 'components/Pages/Login/Left/Main/Form/Main'
import Header from 'components/Pages/Login/Left/Main/Form/Header'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;

  @media (max-width: 1280px) {
    margin-bottom: 32px;
  }
`
const Block = styled.div`
  width: 304px;

  @media (max-width: 1280px) {
    width: 256px;
  }

  @media (max-width: 500px) {
    width: 240px;
  }
`

const Form = () => {
  return (
    <Wrapper>
      <Header />
      <Block>
        <Main />
      </Block>
    </Wrapper>
  )
}

export default Form
