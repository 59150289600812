import { useEffect } from 'react'
import { path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { Radio, Input, Error } from '../../../Common'
import { Description, Group } from '../_styles'
import { emailMask } from 'utils/masks'
import { useTranslation } from 'react-i18next'
import { setEmailValue, setTouched, setEmailError } from 'store/verify'

const EmailBlock = props => {
  const { website } = props
  const { t } = useTranslation()

  const active = useSelector(state => {
    return path(['verify', 'method'], state) === 'email'
  })

  const value = useSelector(state => {
    return path(['verify', 'email'], state)
  })

  const error = useSelector(state => {
    return path(['verify', 'emailError'], state)
  })

  const touched = useSelector(state => {
    return path(['verify', 'touched'], state)
  })

  const dispatch = useDispatch()

  const verify = value => {
    let error = ''

    if (!active) {
      error = ''
    } else if (!emailMask.test(value)) {
      error = t('myCompany.verifyDialog.email.error.notValid')
    }

    dispatch(setEmailError(error))
  }

  useEffect(() => {
    verify(value)
  }, [active])

  const handleChange = e => {
    dispatch(setTouched(false))
    dispatch(setEmailValue(e.target.value))
    verify(e.target.value)
  }

  const handleBlur = () => {
    dispatch(setTouched(true))
    verify(value)
  }

  const hasError = active && touched && error

  return (
    <Group>
      <Radio
        name='method'
        value='email'
        defaultChecked={active}
        disabled={!website}
      >
        {t('myCompany.verifyDialog.email.specify')}
      </Radio>

      <Description>
        {t('myCompany.verifyDialog.email.specifyDescription')}
      </Description>

      <Input
        id='email'
        name='email'
        label={t('myCompany.verifyDialog.email.label')}
        autoComplete='email'
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={!active}
        hasError={hasError}
      />
      <Error>{hasError ? error : ''}</Error>
    </Group>
  )
}

export default EmailBlock
