import { FC } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LOGIN_PAGE_PATH } from 'utils/consts/path'

const Wrapper = styled.div`
  width: 304px;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  color: #717284;
  margin: 12px 0 27px;

  @media (max-width: 1280px) {
    width: 256px;
    height: 48px;
    font-size: 12px;
    line-height: 15px;
    margin: 0 0 16px;
  }

  @media (max-width: 500px) {
    width: 240px;
  }
`
const Title = styled.span`
  cursor: pointer;

  :hover {
    color: var(--color-night);
  }
`

const BackToLogin: FC = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const redirect = () => {
    history.push(LOGIN_PAGE_PATH)
  }

  return (
    <Wrapper>
      <Title onClick={redirect}>{t('confirmPwd.back')}</Title>
    </Wrapper>
  )
}

export default BackToLogin
