import {
  CHECKBOX_LIST,
  filterTypes,
  INTERVAL,
  MULTISELECT_SWITCHER,
  PRODUCT_RANK,
  RADIO_LIST,
  RHEOSTAT,
  RRP_INTERVAL,
  SCORE,
  SWITCHER,
} from './filters'
import i18n from '../i18n'
import {
  offersFiltersKey,
  offersFiltersName,
  offersFiltersTitle,
  offersFiltersTitle as baseOffersFiltersTitle,
} from './offersFilters'

export const buyWineWinesFiltersKey = {
  countryFilter: 'wine.country',
  producerFilter: 'wine.tradeName',
  wineTypeFilter: 'wine.wineType',
  wineColourFilter: 'wine.wineColor',
  sweetnessTypeFilter: 'wine.sweetnessLevel',
  vintageFilter: 'wine.vintage',
  bottleSizeFilter: 'bottleSize',
  packageTypeFilter: 'packageType',
  grapesFilter: 'grapeAndMonocepage',
  certificatesFilter: 'wine.certification',
  alcoholLevelFilter: 'alcoholOnLabel',
  wwlRatingFilter: 'wine.rating',
  nationalGITypeFilter: 'wine.nationalGIType',
  geoIndicationFilter: 'wine.geoIndication',
  brandFilter: 'wine.brand',
  specialClassificationFilter: 'wine.specialClassification',
  rankFilter: 'appellationRating.rank',
  showLatestVintageFilter: 'onlyLatestVintage',
  marginFilter: 'margin',
  bestOfferPriceFilter: 'bestOfferPricePerBottle',
  coefFilter: 'coef',
  minRetailPriceFilter: 'minRetailPrice',
  recommendedRetailPriceFilter: 'recommendedRetailPrice',
  hasBottleImageFilter: 'hasBottleImage',
  hasFrontLabelImageFilter: 'hasFrontLabelImage',
  hasBackLabelImageFilter: 'hasBackLabelImage',
  hasVerifiedRepresentativeFilter: 'hasVerifiedRepresentative',
  havePublicReviewsFilter: 'havePublicReviewsBool',
  monocepageFilter: 'grapeAndMonocepage.monocepage',
}

export const getBuyWineWinesFiltersCollection = () => [
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.wineCountryFilter),
    filterName: offersFiltersName.countryFilter,
    apiFilterName: buyWineWinesFiltersKey.countryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.producerFilter),
    filterName: offersFiltersName.producerFilter,
    apiFilterName: buyWineWinesFiltersKey.producerFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.brandFilter),
    filterName: offersFiltersName.brandFilter,
    apiFilterName: buyWineWinesFiltersKey.brandFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.nationalGITypeFilter),
    filterName: offersFiltersName.nationalGITypeFilter,
    apiFilterName: buyWineWinesFiltersKey.nationalGITypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.geoIndicationFilter),
    filterName: offersFiltersName.geoIndicationFilter,
    apiFilterName: buyWineWinesFiltersKey.geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.specialClassificationFilter),
    filterName: offersFiltersName.specialClassificationFilter,
    apiFilterName: buyWineWinesFiltersKey.specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.wineTypeFilter),
    filterName: offersFiltersName.wineTypeFilter,
    apiFilterName: buyWineWinesFiltersKey.wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.wineColourFilter),
    filterName: offersFiltersName.wineColourFilter,
    apiFilterName: buyWineWinesFiltersKey.wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.sweetnessTypeFilter),
    filterName: offersFiltersName.sweetnessTypeFilter,
    apiFilterName: buyWineWinesFiltersKey.sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.vintageFilter),
    filterName: offersFiltersName.vintageFilter,
    apiFilterName: buyWineWinesFiltersKey.vintageFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(baseOffersFiltersTitle.showLatestVintageFilter),
    filterName: offersFiltersName.showLatestVintageFilter,
    apiFilterName: buyWineWinesFiltersKey.showLatestVintageFilter,
  },
  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(baseOffersFiltersTitle.marginFilter),
    filterName: offersFiltersName.marginFilter,
    apiFilterName: buyWineWinesFiltersKey.marginFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(baseOffersFiltersTitle.bestOfferPriceFilter),
    filterName: offersFiltersName.bestOfferPriceFilter,
    apiFilterName: buyWineWinesFiltersKey.bestOfferPriceFilter,
    defaultIntervalKey: 'bestOfferPricePerBottle',
  },
  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(baseOffersFiltersTitle.coefFilter),
    filterName: offersFiltersName.coefFilter,
    apiFilterName: buyWineWinesFiltersKey.coefFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(baseOffersFiltersTitle.minRetailPriceFilter),
    filterName: offersFiltersName.minRetailPriceFilter,
    apiFilterName: buyWineWinesFiltersKey.minRetailPriceFilter,
    defaultIntervalKey: 'minRetailPrice',
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(baseOffersFiltersTitle.rrpFilter),
    filterName: offersFiltersName.rrpFilter,
    apiFilterName: buyWineWinesFiltersKey.recommendedRetailPriceFilter,
    defaultIntervalKey: 'recommendedRetailPrice',
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(offersFiltersTitle.onlyWithOffersFilter),
    filterName: offersFiltersName.onlyWithOffersFilter,
    apiFilterName: offersFiltersKey().onlyWithOffersFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(baseOffersFiltersTitle.grapesFilter),
    filterName: offersFiltersName.grapesFilter,
    apiFilterName: buyWineWinesFiltersKey.grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: offersFiltersName.monocepageFilter,
    switchTitle: i18n.t(baseOffersFiltersTitle.monocepageFilter),
    switchApiFilterName: buyWineWinesFiltersKey.monocepageFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(baseOffersFiltersTitle.alcoholLevelFilter),
    filterName: offersFiltersName.alcoholLevelFilter,
    apiFilterName: buyWineWinesFiltersKey.alcoholLevelFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.certificatesFilter),
    filterName: offersFiltersName.certificatesFilter,
    apiFilterName: buyWineWinesFiltersKey.certificatesFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.bottleSizeFilter),
    filterName: offersFiltersName.bottleSizeFilter,
    apiFilterName: buyWineWinesFiltersKey.bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.packageTypeFilter),
    filterName: offersFiltersName.packageTypeFilter,
    apiFilterName: buyWineWinesFiltersKey.packageTypeFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(baseOffersFiltersTitle.hasBottleImageFilter),
    filterName: offersFiltersName.hasBottleImageFilter,
    apiFilterName: buyWineWinesFiltersKey.hasBottleImageFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(baseOffersFiltersTitle.hasFrontLabelImageFilter),
    filterName: offersFiltersName.hasFrontLabelImageFilter,
    apiFilterName: buyWineWinesFiltersKey.hasFrontLabelImageFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(baseOffersFiltersTitle.hasBackLabelImageFilter),
    filterName: offersFiltersName.hasBackLabelImageFilter,
    apiFilterName: buyWineWinesFiltersKey.hasBackLabelImageFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(baseOffersFiltersTitle.hasVerifiedRepresentativeFilter),
    filterName: offersFiltersName.hasVerifiedRepresentativeFilter,
    apiFilterName: buyWineWinesFiltersKey.hasVerifiedRepresentativeFilter,
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(baseOffersFiltersTitle.wwlRatingFilter),
    filterName: offersFiltersName.wwlRatingFilter,
    apiFilterName: buyWineWinesFiltersKey.wwlRatingFilter,
  },
  {
    type: filterTypes[PRODUCT_RANK],
    title: i18n.t(baseOffersFiltersTitle.rankFilter),
    filterName: offersFiltersName.rankFilter,
    apiFilterName: buyWineWinesFiltersKey.rankFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(baseOffersFiltersTitle.havePublicReviewsFilter),
    filterName: offersFiltersName.havePublicReviewsFilter,
    apiFilterName: buyWineWinesFiltersKey.havePublicReviewsFilter,
  },
]
