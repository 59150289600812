import styled from 'styled-components'
import BG from 'assets/images/auth/login-background.jpg'

export const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  background-color: #11132e;
  background-image: url(${BG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  min-width: 318px;
  display: flex;
  justify-content: center;
  position: relative;
`
export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  min-height: 868px;

  @media (max-width: 768px) {
    min-height: 1124px;
  }

  @media (max-width: 450px) {
    min-height: 868px;
  }
`
export const Right = styled.div`
  width: 40px;
  margin-left: 32px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }

  @media (max-width: 880px) {
    display: none;
  }
`
