import Main from 'components/Pages/ForgotPassword/Left/Main'
import Header from 'components/Pages/Login/Left/Header'
import Footer from 'components/Pages/Login/Left/Footer'
import { Wrapper } from 'components/Pages/Login/Left'

const Left = () => {
  return (
    <Wrapper>
      <Header />
      <Main />
      <Footer />
    </Wrapper>
  )
}

export default Left
