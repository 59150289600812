import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import SuccessIcon from 'components/Pages/ConfirmPassword/Icons/Success'

const Icon = styled.div`
  width: 67px;
  height: 85px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1280px) {
    width: 67px;
    height: 72px;

    svg {
      width: 67px;
      height: 72px;
    }
  }
`

const Notification = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Success = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #717284;
  font: var(--typography-18-28-medium);

  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const SuccessMessage: FC = () => {
  const { t } = useTranslation()

  return (
    <Notification>
      <Icon>
        <SuccessIcon />
      </Icon>
      <Success>{t('confirmPwd.success')}</Success>
    </Notification>
  )
}

export default SuccessMessage
