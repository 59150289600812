import styled from 'styled-components'
import Main from './Main'
import Header from './Header'
import Footer from './Footer'

const Wrapper = styled.div`
  max-width: 1048px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1280px) {
    max-width: 792px;
  }

  @media (max-width: 880px) {
    max-width: 596px;
  }

  @media (max-width: 690px) {
    max-width: 496px;
  }

  @media (max-width: 600px) {
    max-width: 396px;
  }

  @media (max-width: 500px) {
    max-width: 320px;
  }

  @media (max-width: 450px) {
    max-width: 288px;
  }
`

const Left = () => {
  return (
    <Wrapper>
      <Header />
      <Main />
      <Footer />
    </Wrapper>
  )
}

export default Left
