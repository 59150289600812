import { createContext } from 'react'

const ScreenContext = createContext({
  isMobile: undefined,
  isTablet: undefined,
  isDesktop: undefined,
  size: undefined,
  container: {
    s: undefined,
    m: undefined,
    l: undefined,
    xl: undefined,
  },
})

export default ScreenContext
