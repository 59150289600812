import { FC, HTMLAttributes, useId, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EyeIcon, EyeInvisibleIcon } from 'components/Common/Icons'
import {
  Wrapper,
  Container,
  Label,
  Block,
  IconWrapper,
  IconBlock,
  Icon,
  Input,
  Error,
} from 'components/Pages/ConfirmPassword/Left/Main/Form/Main/PasswordInput/_styles'

interface PasswordInputProps extends HTMLAttributes<HTMLInputElement> {
  label: string
  error: string | null
  autoComplete?: string
}

const PasswordInput: FC<PasswordInputProps> = ({ label, error, ...props }) => {
  const { t } = useTranslation()

  const id = useId()

  const [type, setType] = useState('password')
  const toggle = () => {
    setType(type === 'password' ? 'text' : 'password')
  }

  return (
    <Wrapper>
      <Container>
        <Block>
          <Input
            {...props}
            type={type}
            id={id}
            maxLength={50}
            placeholder=' '
            spellCheck='false'
            $error={!!error}
          />
          <Label htmlFor={id}>{label}</Label>
        </Block>
        <IconWrapper>
          <IconBlock onClick={toggle}>
            <Icon>
              {type === 'password' ? (
                <EyeInvisibleIcon size={24} color='#a0a1ad' />
              ) : (
                <EyeIcon size={24} color='#a0a1ad' />
              )}
            </Icon>
          </IconBlock>
        </IconWrapper>
      </Container>
      <Error>{error ? t(error) : null}</Error>
    </Wrapper>
  )
}

export default PasswordInput
