import { createGlobalStyle, css } from 'styled-components'

const zIndexInput = 18
const zIndexDialog = 201

export const GlobalStyles = createGlobalStyle`
  html {
    --color-velvet: #3B42A5;
    --color-night: #121432;
    --color-peach: #F7D7AE;
    --color-white: #fff;
    --color-green: #00A042;
    --color-red: #D00A00;
    --color-grey: #A6B0BF;
    --color-brand-red: #88122B;
    --color-brand-red-hover: #5F0D1E;
    --color-brand-black: #000;
    --color-orange: #CC7400;

    --color-night-sky: #121432;
    --color-sw-10: #E7E7EA;
    --color-sw-20: #D0D0D6;
    --color-sw-40: #A0A1AD;
    --color-sw-60: #717284;
    --color-sw-100: #121432;

    --color-sns-10: #E7E7EA;
    --color-sns-20: #D0D0D6;
    --color-sns-30: #B7B8C1;
    --color-sns-40: #A0A1AD;
    --color-sns-50: #888998;
    --color-sns-60: #717284;
    --color-sns-70: #595A6F;
    --color-sns-80: #41435B;
    --color-sns-90: #292B46;

    --color-g-3: #f9f9f9;
    --color-grey2-G-2: #F3F3F5;

    --color-pv-30: #C4C6E4;

    --z-index-input: ${zIndexInput};
    --z-index-input-helptip: ${zIndexInput + 1};
    --z-index-input-dropdown: ${zIndexInput + 2};
    --z-index-select: ${zIndexInput + 3};
    --z-index-input-opened: ${zIndexInput + 4};
    --z-index-footer: ${zIndexInput + 5};
    --z-index-table-row-menu: ${zIndexDialog - 2};
    --z-index-sidebar: ${zIndexDialog - 1};
    --z-index-dialog: ${zIndexDialog};
    --z-index-tooltip: ${zIndexDialog + 1};

    --typography-8-10-bold: 700 8px/10px 'Montserrat', sans-serif;
    --typography-9-12-semibold: 600 9px/12px 'Montserrat', sans-serif;
    --typography-9-16-medium: 500 9px/16px 'Montserrat', sans-serif;
    --typography-10-12-normal: 400 10px/12px 'Montserrat', sans-serif;
    --typography-10-16-medium: 500 10px/16px 'Montserrat', sans-serif;
    --typography-10-16-normal: 400 10px/16px 'Montserrat', sans-serif;
    --typography-10-16-regular: 400 10px/16px 'Montserrat', sans-serif;
    --typography-10-16-semibold: 600 10px/16px 'Montserrat', sans-serif;
    --typography-11-12-bold: 700 11px/12px 'Montserrat', sans-serif;
    --typography-11-16-bold: 700 11px/16px 'Montserrat', sans-serif;
    --typography-11-16-medium: 500 11px/16px 'Montserrat', sans-serif;
    --typography-11-16-normal: 400 11px/16px 'Montserrat', sans-serif;
    --typography-11-16-regular: 400 11px/16px 'Montserrat', sans-serif;
    --typography-11-16-semibold: 600 11px/16px 'Montserrat', sans-serif;
    --typography-12-15-medium: 500 12px/15px 'Montserrat', sans-serif;
    --typography-12-15-semibold: 600 12px/15px 'Montserrat', sans-serif;
    --typography-12-16-bold: 700 12px/16px 'Montserrat', sans-serif;
    --typography-12-16-medium: 500 12px/16px 'Montserrat', sans-serif;
    --typography-12-16-normal: 400 12px/16px 'Montserrat', sans-serif;
    --typography-12-16-regular: 400 12px/16px 'Montserrat', sans-serif;
    --typography-12-16-semibold: 600 12px/16px 'Montserrat', sans-serif;
    --typography-12-18-medium: 500 12px/18px 'Montserrat', sans-serif;
    --typography-12-18-normal: 400 12px/18px 'Montserrat', sans-serif;
    --typography-12-18-regular: 400 12px/18px 'Montserrat', sans-serif;
    --typography-12-18-semibold: 600 12px/18px 'Montserrat', sans-serif;
    --typography-12-20-medium: 500 12px/20px 'Montserrat', sans-serif;
    --typography-12-20-normal: 400 12px/20px 'Montserrat', sans-serif;
    --typography-12-20-regular: 400 12px/20px 'Montserrat', sans-serif;
    --typography-12-20-semibold: 600 12px/20px 'Montserrat', sans-serif;
    --typography-12-24-medium: 500 12px/24px 'Montserrat', sans-serif;
    --typography-12-32-semibold: 600 12px/32px 'Montserrat', sans-serif;
    --typography-13-16-medium: 500 13px/16px 'Montserrat', sans-serif;
    --typography-13-16-semibold: 600 13px/16px 'Montserrat', sans-serif;
    --typography-13-20-medium: 500 13px/20px 'Montserrat', sans-serif;
    --typography-13-20-normal: 400 13px/20px 'Montserrat', sans-serif;
    --typography-13-20-semibold: 600 13px/20px 'Montserrat', sans-serif;
    --typography-14-15-semibold: 600 14px/15px 'Montserrat', sans-serif;
    --typography-14-16-bold: 700 14px/16px 'Montserrat', sans-serif;
    --typography-14-16-medium: 500 14px/16px 'Montserrat', sans-serif;
    --typography-14-16-normal: 400 14px/16px 'Montserrat', sans-serif;
    --typography-14-16-regular: 400 14px/16px 'Montserrat', sans-serif;
    --typography-14-16-semibold: 600 14px/16px 'Montserrat', sans-serif;
    --typography-14-17-bold: 700 14px/17px 'Montserrat', sans-serif;
    --typography-14-17-normal: 400 14px/17px 'Montserrat', sans-serif;
    --typography-14-18-medium: 500 14px/18px 'Montserrat', sans-serif;
    --typography-14-18-regular: 400 14px/18px 'Montserrat', sans-serif;
    --typography-14-18-semibold: 600 14px/18px 'Montserrat', sans-serif;
    --typography-14-20-bold: 700 14px/20px 'Montserrat', sans-serif;
    --typography-14-20-medium: 500 14px/20px 'Montserrat', sans-serif;
    --typography-14-20-normal: 400 14px/20px 'Montserrat', sans-serif;
    --typography-14-20-regular: 400 14px/20px 'Montserrat', sans-serif;
    --typography-14-20-semibold: 600 14px/20px 'Montserrat', sans-serif;
    --typography-14-21-bold: 700 14px/21px 'Montserrat', sans-serif;
    --typography-14-21-semibold: 600 14px/21px 'Montserrat', sans-serif;
    --typography-14-22-semibold: 600 14px/22px 'Montserrat', sans-serif;
    --typography-14-24-bold: 700 14px/24px 'Montserrat', sans-serif;
    --typography-14-24-medium: 500 14px/24px 'Montserrat', sans-serif;
    --typography-14-24-normal: 400 14px/24px 'Montserrat', sans-serif;
    --typography-14-32-normal: 400 14px/32px 'Montserrat', sans-serif;
    --typography-16-18-bold: 700 16px/18px 'Montserrat', sans-serif;
    --typography-16-20-bold: 700 16px/20px 'Montserrat', sans-serif;
    --typography-16-20-medium: 500 16px/20px 'Montserrat', sans-serif;
    --typography-16-20-normal: 400 16px/20px 'Montserrat', sans-serif;
    --typography-16-20-semibold: 600 16px/20px 'Montserrat', sans-serif;
    --typography-16-21-bold: 700 16px/21px 'Montserrat', sans-serif;
    --typography-16-21-semibold: 600 16px/21px 'Montserrat', sans-serif;
    --typography-16-24-bold: 700 16px/24px 'Montserrat', sans-serif;
    --typography-16-24-medium: 500 16px/24px 'Montserrat', sans-serif;
    --typography-16-24-semibold: 600 16px/24px 'Montserrat', sans-serif;
    --typography-18-20-semibold: 600 18px/20px 'Montserrat', sans-serif;
    --typography-18-24-bold: 700 18px/24px 'Montserrat', sans-serif;
    --typography-18-24-medium: 500 18px/24px 'Montserrat', sans-serif;
    --typography-18-24-semibold: 600 18px/24px 'Montserrat', sans-serif;
    --typography-18-28-bold: 700 18px/28px 'Montserrat', sans-serif;
    --typography-18-28-medium: 500 18px/28px 'Montserrat', sans-serif;
    --typography-18-28-semibold: 600 18px/28px 'Montserrat', sans-serif;
    --typography-19-23-semibold: 600 19px/23px 'Montserrat', sans-serif;
    --typography-20-32-medium: 500 20px/32px 'Montserrat', sans-serif;
    --typography-21-26-regular: 400 21px/26px 'Montserrat', sans-serif;
    --typography-21-28-bold: 700 21px/28px 'Montserrat', sans-serif;
    --typography-22-28-semibold: 600 22px/28px 'Montserrat', sans-serif;
    --typography-22-30-medium: 500 22px/30px 'Montserrat', sans-serif;
    --typography-24-24-medium: 500 24px/24px 'Montserrat', sans-serif;
    --typography-24-24-semibold: 600 24px/24px 'Montserrat', sans-serif;
    --typography-24-28-bold: 700 24px/28px 'Montserrat', sans-serif;
    --typography-24-28-semibold: 600 24px/28px 'Montserrat', sans-serif;
    --typography-24-32-bold: 700 24px/32px 'Montserrat', sans-serif;
    --typography-24-32-semibold: 600 24px/32px 'Montserrat', sans-serif;
    --typography-24-34-medium: 500 24px/34px 'Montserrat', sans-serif;
    --typography-32-48-semibold: 600 32px/48px 'Montserrat', sans-serif;
    --typography-44-62-bold: 700 44px/62px 'Montserrat', sans-serif;
    --typography-56-72-bold: 700 56px/72px 'Montserrat', sans-serif;


    --typography-semibold: 'Montserrat-Semibold', sans-serif;
    --typography-bold: 'Montserrat-Bold', sans-serif;
    --typography-medium: 'Montserrat-Medium', sans-serif;
    --typography-regular: 'Montserrat-Regular', sans-serif;
    --typography-normal: 'Montserrat', sans-serif;
  }

  html, body {
    height: 100%;
    width: 100%;
    font-family: var(--typography-normal);
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  .dragging {
    scroll-snap-type: none !important;

    * {
      pointer-events: none;
    }
  }
`

export const lineClamp = (linesCount = 1) => {
  if (linesCount === 1) {
    return css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `
  }

  return css`
    display: -webkit-box;
    -webkit-line-clamp: ${linesCount};
    -webkit-box-orient: vertical;
    overflow: hidden;
  `
}

export const styledScrollbar = css`
  scrollbar-color: rgba(18, 20, 50, 0.12) transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: none;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 6px;
    margin: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(18, 20, 50, 0.12);
    border-radius: 6px;
  }
`

export const hideScrollbar = css`
  scrollbar-color: transparent transparent;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
