import styled from 'styled-components'

const Wrapper = styled.svg`
  fill: #a0a1ad;

  :hover {
    fill: #717284;
  }
`

const NotSee = () => {
  return (
    <Wrapper
      width='24'
      height='24'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.6304 0.233988C22.9424 -0.0779958 23.4543 -0.0779958 23.7662 0.233988L23.766 0.234243C24.078 0.546201 24.078 1.05815 23.766 1.37014L1.3698 23.768C1.20983 23.92 1.00988 24 0.801886 24C0.593896 24 0.393943 23.92 0.23397 23.768C-0.07799 23.4561 -0.07799 22.9441 0.23397 22.6321L5.7611 17.1046C3.36145 15.3046 2.07366 12.8569 1.67373 12.0009C2.32974 10.6171 5.47315 4.80162 12 4.80162C13.9038 4.80162 15.5036 5.28956 16.8473 6.01751L22.6304 0.233988ZM8.00089 12.0007C8.00089 12.8167 8.24887 13.5686 8.66479 14.2005L14.1999 8.66506C13.568 8.24909 12.8161 8.00111 12.0002 8.00111C9.79259 8.00111 8.00089 9.79294 8.00089 12.0007ZM15.9285 11.2729L17.2153 9.98508L19.351 7.8492C21.0457 9.47311 21.9906 11.289 22.3265 12.0009C21.6705 13.3847 18.5271 19.2002 12.0002 19.2002C10.7364 19.2002 9.60056 18.9842 8.58574 18.6163L9.98552 17.2164L11.2723 15.9285C11.5043 15.9765 11.7522 16.0005 12.0002 16.0005C14.2078 16.0005 15.9995 14.2087 15.9995 12.0009C15.9995 11.7529 15.9755 11.5049 15.9285 11.2729Z'
      />
    </Wrapper>
  )
}

export default NotSee
