import styled from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const Container = styled.div`
  position: relative;
`
type BlockType = {
  isSuccess: boolean
}
export const Block = styled.div<BlockType>`
  opacity: ${props => (props.isSuccess ? '0' : '1')};
`

export const Info = styled.div`
  letter-spacing: 0.3px;
  color: #717284;
  margin-bottom: 16px;
  font: var(--typography-18-28-medium);

  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const Notification = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Success = styled(Info)`
  margin-top: 24px;
  text-align: center;

  @media (max-width: 1280px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const Icon = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1280px) {
    width: 64px;
    height: 64px;

    svg {
      width: 64px;
      height: 64px;
    }
  }
`

export const Error = styled.div`
  margin: 4px 0;
  min-height: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--color-red);

  @media (max-width: 1280px) {
    font-size: 12px;
    line-height: 12px;
    min-height: 12px;
    margin: 2px 0;
  }
`
