import { lineClamp } from 'components/Common/GlobalStyles'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { menuItemStyles } from '../../_styles'

export const IconWrapper = styled.div`
  display: flex;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  width: 20px;
  height: 20px;
  position: relative;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const Text = styled.span`
  flex: 1;
  user-select: none;
  ${lineClamp()};
`

export const MenuItemWrapper = styled.div<{
  $active?: boolean
  $disabled?: boolean
  $sub?: boolean
  $noLeftLine?: boolean
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 16px;

  & & {
    margin: 0;
  }

  ${props =>
    menuItemStyles({ active: props.$active, disabled: props.$disabled })};

  ${props =>
    !props.$noLeftLine &&
    props.$active &&
    css`
      &::before {
        content: '';
        width: 4px;
        height: 44px;
        position: absolute;
        left: -16px;
        top: 0;
        border-radius: 0 2px 2px 0;
        background: var(--grey-white, #fff);
      }
    `}

  ${props =>
    props.$sub &&
    css`
      &::after {
        content: '';
        height: 1px;
        position: absolute;
        left: 48px;
        right: 0;
        top: 0;
        background: #41435b;
      }
    `}
`

const MenuItemStyles = css`
  box-sizing: border-box;
  display: flex;
  padding: 7px 16px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  gap: 12px;
  width: 100%;
  max-width: calc(var(--sidebar-width) - 16px);
  font: var(--typography-13-20-medium);
  color: inherit;
`

export const LinkMenuItem = styled(Link)`
  ${MenuItemStyles};
  text-decoration: none;
  padding: 12px;
  color: inherit;
`

export const LinkFakeItem = styled.div<{
  $disabled?: boolean
}>`
  ${MenuItemStyles};
  padding: 12px;
`

export const ButtonMenuItem = styled.button<{
  $active?: boolean
  $disabled?: boolean
}>`
  all: unset;
  ${MenuItemStyles};
  padding: 12px;
`

export const CollapseButton = styled.div<{ $opened: boolean }>`
  width: 20px;
  height: 20px;

  svg {
    transform: rotate(${props => (props.$opened ? 0 : 180)}deg);
  }
`
