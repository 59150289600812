import { FC } from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { redirectProductPage } from 'utils/apollo/handleAuthError'
import { isLogin } from 'utils/token'
import { LOGIN_PAGE_PATH } from 'utils/consts/path'

const PrivateRoute: FC<RouteProps> = ({ component: Component, ...rest }) => {
  const userLoggedIn = isLogin()
  if (!userLoggedIn) {
    redirectProductPage()

    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: LOGIN_PAGE_PATH,
              state: { from: location },
            }}
          />
        )}
      />
    )
  }

  return <Route {...rest} component={Component} />
}

export default PrivateRoute
