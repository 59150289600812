import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`
export const Label = styled.label`
  position: absolute;
  cursor: text;
  z-index: 2;
  top: 26px;
  left: 24px;
  color: #717284;
  transition: top 0.2s ease, font-size 0.2s ease;
  font: var(--typography-16-20-semibold);

  @media (max-width: 1280px) {
    font-size: 14px;
    top: 14px;
    left: 16px;
  }
`
export const Block = styled.div`
  width: 100%;
  height: 72px;
  position: relative;
  border-radius: 8px;

  :hover {
    box-shadow: 0 3px 5px rgba(95, 99, 104, 0.05),
      0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05);
  }

  @media (max-width: 1280px) {
    height: 48px;
  }

  input:-webkit-autofill::first-line {
    font-size: 16px;
    font-family: var(--typography-semibold);
    letter-spacing: 0.35em;

    @media (max-width: 1280px) {
      font-size: 14px;
    }
  }

  input:focus + ${Label}, input:not(:placeholder-shown) + ${Label} {
    font-size: 12px;
    top: 15px;
    font-family: var(--typography-regular);

    @media (max-width: 1280px) {
      top: 5px;
    }
  }
`
export const IconWrapper = styled.div`
  min-width: 24px;
  height: 72px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  cursor: pointer;
  box-sizing: border-box;

  @media (max-width: 1280px) {
    height: 48px;
  }
`
export const IconBlock = styled.div`
  padding: 0 23px 0 5px;
  margin-right: 1px;
  height: 38px;
  display: flex;
  align-items: center;

  @media (max-width: 1280px) {
    padding: 0 15px 0 5px;
  }
`
export const Icon = styled.div`
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1280px) {
    width: 16px;
  }
`

export const Input = styled.input<{ $error?: boolean }>`
  width: 100%;
  height: 72px;
  appearance: none;
  box-sizing: border-box;
  border: ${props =>
    props.$error ? '1px solid var(--color-red)' : '1px solid #e2e2ea'};
  border-radius: 8px;
  outline: none;
  padding: 15px 58px 0 23px;
  color: ${props => (props.$error ? 'var(--color-red)' : 'var(--color-night)')};
  font-size: 16px;
  font-family: var(--typography-semibold);

  :hover + ${Label} {
    color: var(--color-night);
  }

  letter-spacing: ${props => (props.type === 'password' ? '0.35em' : '0.1px')};
  transition: letter-spacing 0.2s, border 0.2s;

  @media (max-width: 1280px) {
    height: 48px;
    padding: 15px 40px 0 15px;
    font-size: 14px;
  }
`
export const Error = styled.div`
  margin: 4px 0 4px 24px;
  min-height: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--color-red);

  @media (max-width: 1280px) {
    font-size: 12px;
    line-height: 12px;
    min-height: 12px;
    margin: 2px 0 2px 24px;
  }
`
