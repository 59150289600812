import styled from 'styled-components'
import Main from 'components/Pages/ForgotPassword/Left/Main/Form/Main'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Header = styled.div`
  width: 100%;
  letter-spacing: 0.4px;
  color: var(--color-night);
  font: var(--typography-24-28-semibold);
  margin: 40px 0 32px;

  @media (max-width: 1280px) {
    margin: 24px 0 16px;
    font-size: 18px;
    line-height: 28px;
  }
`
const Block = styled.div`
  width: 304px;

  @media (max-width: 1280px) {
    width: 256px;
  }

  @media (max-width: 500px) {
    width: 240px;
  }
`

const Form = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Block>
        <Header>{t('forgotPwd.header')}</Header>
        <Main />
      </Block>
    </Wrapper>
  )
}

export default Form
