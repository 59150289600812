import { useSelector } from 'react-redux'
import { getUserData, getSelectedCompany } from 'store/me'
import { Roles } from 'utils/consts/userRoles'
import { PRODUCER_CATEGORY_ID } from 'utils/consts/wineOffer'

export const useCheckAccessToDatabase = () => {
  const userData = useSelector(getUserData)
  const selectedCompany = useSelector(getSelectedCompany)
  const role = userData?.role?.code
  const companies = userData?.companies ?? []

  if (
    role === Roles.PERSON &&
    selectedCompany?.companyCategory?.id === PRODUCER_CATEGORY_ID
  ) {
    return false
  }
  return role !== Roles.PERSON || companies.some(item => item.isVerifiedUser)
}
