import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Ok from 'components/Common/Icons/Ok'
import Loader from 'components/Common/Loader'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Container = styled(PrimaryButtonFlexable48)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  height: 72px;
  cursor: ${props => (props.fakeDisabled ? 'auto' : 'pointer')};
  border-radius: 8px;
  padding: 0 24px;
  font: var(--typography-16-20-semibold);
  letter-spacing: 0.5px;
  transition: background-color 0.2s;

  @media (max-width: 1280px) {
    height: 48px;
  }
`
const Title = styled.div`
  font-size: 16px;

  @media (max-width: 1280px) {
    font-size: 14px;
  }
`

const Button = props => {
  const { handleRequest, disabled, loading, isSuccess } = props

  const { t } = useTranslation()

  const [size, setSize] = useState(1920)

  useEffect(() => {
    const r = window.innerWidth || 1920
    setSize(r)
  }, [])

  return (
    <Wrapper>
      <Container onClick={handleRequest} fakeDisabled={disabled}>
        {isSuccess ? (
          <Ok />
        ) : loading ? (
          <Loader color='#fff' size={size <= 1280 ? '23px' : '30px'} />
        ) : (
          <Title>{t('forgotPwd.send')}</Title>
        )}
      </Container>
    </Wrapper>
  )
}

export default Button
