import styled from 'styled-components'
import ErrorIcon from 'components/Pages/ConfirmMail/Icons/Error'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Icon = styled.div`
  width: 80px;
  height: 80px;

  @media (max-width: 1280px) {
    width: 64px;
    height: 64px;

    svg {
      width: 64px;
      height: 64px;
    }
  }
`
const Title = styled.div`
  margin: 16px 0 8px;
  display: flex;
  justify-content: center;
  letter-spacing: 0.4px;
  color: var(--color-night);
  font: var(--typography-24-28-semibold);

  @media (max-width: 1280px) {
    font-size: 18px;
  }
`
const Text = styled.div`
  letter-spacing: 0.3px;
  color: #717284;
  text-align: center;
  font: var(--typography-18-28-medium);

  @media (max-width: 1280px) {
    font-size: 14px;
    line-height: 20px;
  }
`

const Error = props => {
  const { message } = props
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Icon>
        <ErrorIcon />
      </Icon>
      <Title>{t('common.oops')}</Title>
      <Text>{message}</Text>
    </Wrapper>
  )
}

export default Error
