import { ProductELabel, Product } from 'api/schemas'

export enum Step {
  wine,
  linkSettings,
  preview,
}

type LinkType = 'digital' | 'simple'

export type SettingsType = {
  linkType: LinkType
  gtin: string
  useWineGTIN: boolean
}
export type AddELabelModalStore = {
  step: Step
  openedSteps: Step
  product: ProductData | null
  settings: SettingsType | null
  eLabel: {
    id: string
    shortUrl: string
  } | null
  gtinError: string | null
}

export type ProductData = Partial<Product | ProductELabel>
