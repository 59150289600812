import { useEffect } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useChangeEmailMutation } from 'api/hooks'
import Error from '../Error'
import Button from '../Button'
import Success from './Success'
import LoadingIndicator from '../LoadingIndicator'
import { Block, Wrapper } from '../_styles'
import { captureException } from 'utils/sentry'

const Change = () => {
  const [changeMail, { data, loading, error }] = useChangeEmailMutation()

  const location = useLocation()

  useEffect(() => {
    const params = queryString.parse(location.search)
    const token = params?.changeEmailToken

    if (token) {
      changeMail({
        variables: { token },
        context: { ignoreAccessErrors: true },
      }).catch(captureException)
    }
  }, [])

  return (
    <Wrapper>
      <Block>
        {loading && <LoadingIndicator />}
        {error && <Error message={error.message} />}
        {data && <Success />}
      </Block>
      <Button disabled={!!error} />
    </Wrapper>
  )
}

export default Change
