const Error = () => (
  <svg
    width='80'
    height='80'
    viewBox='0 0 80 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M58.8232 38.7071C58.1398 34.1449 53.9839 30.8941 49.3663 30.8941H44.0653C43.1048 30.8941 42.3106 31.6883 42.3106 32.6488C42.3106 33.6092 43.1048 34.4035 44.0653 34.4035H49.4217C52.377 34.4035 55.0367 36.5091 55.3692 39.4459C55.6832 42.0872 54.1871 44.4144 51.9522 45.3934L54.538 47.9792C57.5487 46.0952 59.4142 42.6043 58.8232 38.7071ZM25.9089 24.5403C25.1885 23.8199 24.0249 23.8199 23.3045 24.5403C22.5842 25.2606 22.5842 26.4242 23.3045 27.1446L27.7374 31.5775C24.1542 33.0551 21.6976 36.6938 22.0301 40.8866C22.418 45.7628 26.777 49.3645 31.6532 49.3645H36.8618C37.8223 49.3645 38.6165 48.5703 38.6165 47.6098C38.6165 46.6494 37.8223 45.8551 36.8618 45.8551H31.5239C28.5132 45.8551 25.7981 43.6571 25.521 40.6465C25.2439 37.4696 27.5712 34.7913 30.6004 34.4589L34.4791 38.3377C33.6849 38.5039 33.0754 39.1873 33.0754 40.0369V40.2216C33.0754 41.1821 33.8696 41.9763 34.8301 41.9763H38.1178L42.3106 46.1691V49.3645H45.506L51.6012 55.4597C52.3216 56.1801 53.4852 56.1801 54.2056 55.4597C54.9259 54.7394 54.9259 53.5758 54.2056 52.8554L25.9089 24.5403ZM47.8517 40.0369C47.8517 39.0765 47.0575 38.2823 46.097 38.2823H44.878L47.6301 41.0343C47.7594 40.7942 47.8517 40.5172 47.8517 40.2216V40.0369Z'
      fill='#D00A00'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM40 76C59.8823 76 76 59.8823 76 40C76 20.1177 59.8823 4 40 4C20.1177 4 4 20.1177 4 40C4 59.8823 20.1177 76 40 76Z'
      fill='#E7E7EA'
    />
  </svg>
)

export default Error
