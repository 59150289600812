import styled from 'styled-components'
import icon from './_assets/icon.svg'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 348px;
`
const Text = styled.div`
  font: var(--typography-14-20-medium);
  font-weight: 600;
  letter-spacing: 0.3px;
  color: var(--color-sw-60);
  margin-top: 32px;
  text-align: center;
`

const NeedVerifyEmail = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <img src={icon} alt='not allowed icon' />
      <Text>{t('login.verifyEmailFirst')}</Text>
    </Wrapper>
  )
}

export default NeedVerifyEmail
