import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ls } from 'utils/my-localstorage'
import { LOGIN_PAGE_PATH } from 'utils/consts/path'
import { clearState } from 'store/me'
import { removeTokens } from 'utils/token'

export function useLogout() {
  const history = useHistory()
  const dispatch = useDispatch()

  return () => {
    removeTokens()
    ls.removeItem('wwl-person_category')
    ls.removeItem('wwl-verified_company')
    ls.removeItem('wwl-role_code')
    ls.removeItem('wwl-user')
    ls.removeItem('filters-acc-buy-wine-offers')
    ls.removeItem('filters-acc-sell-wine-my-offers')
    ls.removeItem('filters-acc-database-brands')
    ls.removeItem('filters-acc-database-wines')
    ls.removeItem('filters-acc-database-producers')
    ls.removeItem('filters-acc-database-producers-duplicates')
    ls.removeItem('preset-filters-acc-sell-wine-my-offers')
    ls.removeItem('preset-filters-acc-buy-wine-offers')
    ls.removeItem('preset-filters-acc-database-wines')
    ls.removeItem('preset-filters-acc-database-brands')
    ls.removeItem('preset-filters-acc-database-producers')
    ls.removeItem('preset-filters-acc-database-producers-duplicates')
    dispatch(clearState())
    dispatch({ type: 'tableFilters/clear' })
    history.push(LOGIN_PAGE_PATH, { isLogout: true })
  }
}
