import i18n from 'utils/i18n'
import { without } from 'ramda'
import { Roles } from 'utils/consts/userRoles'
import {
  filterTypes,
  CHECKBOX_LIST,
  DATE_INTERVAL,
  SEARCH_USERS,
  RADIO_LIST,
  SCORE,
  INTERVAL,
  PRICE_INTERVAL,
  SWITCHER,
  RHEOSTAT,
  MULTISELECT_SWITCHER,
  offersFiltersTitle as baseOffersFiltersTitle,
} from 'utils/consts'

export const databaseFiltersName = {
  countryFilter: 'countryFilter',
  usersFilter: 'usersFilter',
  sourceFilter: 'sourceFilter',
  creationDateFilter: 'creationDateFilter',
  lastUpdateFilter: 'lastUpdateFilter',
  checkedByFilter: 'checkedByFilter',
  invalidRecordFilter: 'invalidRecordFilter',
  registrationFilter: 'registrationFilter',
  producerFilter: 'producerFilter',
  producerWWLFilter: 'producerWWLFilter',
  euGITypeFilter: 'euGITypeFilter',
  nationalGITypeFilter: 'nationalGITypeFilter',
  wineRegionEUFilter: 'wineRegionEUFilter',
  geoIndicationFilter: 'geoIndicationFilter',
  wineTypeFilter: 'wineTypeFilter',
  wineColourFilter: 'wineColourFilter',
  sweetnessTypeFilter: 'sweetnessTypeFilter',
  vintageFilter: 'vintageFilter',
  bottleSizeFilter: 'bottleSizeFilter',
  packageTypeFilter: 'packageTypeFilter',
  grapesFilter: 'grapesFilter',
  certificatesFilter: 'certificatesFilter',
  alcoholLevelFilter: 'alcoholLevelFilter',
  cellarDoorPriceFilter: 'cellarDoorPriceFilter',
  wwlRatingFilter: 'wwlRatingFilter',
  brandFilter: 'brandFilter',
  monocepageFilter: 'monocepageFilter',
  legalEntityFilter: 'legalEntityFilter',
  synonymsFilter: 'synonymsFilter',
  havePublicReviewsFilter: 'havePublicReviewsFilter',
  specialClassificationFilter: 'specialClassificationFilter',
  parentGeoIndicationFilter: 'parentGeoIndicationFilter',
  wineTerritoryTypeFilter: 'wineTerritoryTypeFilter',
  dateStartFilter: 'dateStartFilter',
  dateEndFilter: 'dateEndFilter',
  participantsFilter: 'participantsFilter',
  exhibitionTypeFilter: 'exhibitionTypeFilter',
  retailPriceFilter: 'retailPriceFilter',
  hasVerifiedRepresentativeFilter: 'hasVerifiedRepresentativeFilter',
  hasWebsiteFilter: 'hasWebsiteFilter',
  hasEmailFilter: 'hasEmailFilter',
  hasPhoneFilter: 'hasPhoneFilter',
  onlyLatestVintageFilter: 'onlyLatestVintageFilter',
  hasBottleImageFilter: 'hasBottleImageFilter',
  hasFrontLabelImageFilter: 'hasFrontLabelImageFilter',
  hasBackLabelImageFilter: 'hasBackLabelImageFilter',
}
const databaseFiltersTitle = {
  countryFilter: 'filters.names.country',
  usersFilter: 'filters.names.users',
  sourceFilter: 'filters.names.source',
  creationDateFilter: 'filters.names.creationDate',
  lastUpdateFilter: 'filters.names.lastUpdate',
  checkedByFilter: 'filters.names.checkedBy',
  invalidRecordFilter: 'filters.names.invalidRecord',
  registrationFilter: 'filters.names.registration',
  producerFilter: 'filters.names.producer',
  producerWWLFilter: 'filters.names.producerWWLFilter',
  euGITypeFilter: 'filters.names.euGITypeFilter',
  nationalGITypeFilter: 'filters.names.nationalGITypeFilter',
  wineRegionEUFilter: 'filters.names.wineRegionEUFilter',
  geoIndicationFilter: 'filters.names.geoIndicationFilter',
  wineTypeFilter: 'filters.names.wineTypeFilter',
  wineColourFilter: 'filters.names.wineColourFilter',
  sweetnessTypeFilter: 'filters.names.sweetnessTypeFilter',
  vintageFilter: 'filters.names.vintageFilter',
  bottleSizeFilter: 'filters.names.bottleSizeFilter',
  packageTypeFilter: 'filters.names.packageTypeFilter',
  grapesFilter: 'filters.names.grapesFilter',
  certificatesFilter: 'filters.names.certificatesFilter',
  alcoholLevelFilter: 'filters.names.alcoholLevelFilter',
  cellarDoorPriceFilter: 'filters.names.recommendedRetailPriceFilter',
  wwlRatingFilter: 'filters.names.wwlRatingFilter',
  brandFilter: 'filters.names.brandFilter',
  monocepageFilter: 'filters.names.monocepageFilter',
  legalEntityFilter: 'filters.names.legalEntityFilter',
  synonymsFilter: 'filters.names.synonymsFilter',
  havePublicReviewsFilter: 'filters.names.havePublicReviewsFilters',
  specialClassificationFilter: 'filters.names.specialClassificationFilter',
  parentGeoIndicationFilter: 'filters.names.parentGeoIndicationFilter',
  wineTerritoryTypeFilter: 'filters.names.wineTerritoryTypeFilter',
  dateStartFilter: 'filters.names.startDateFilter',
  dateEndFilter: 'filters.names.endDateFilter',
  participantsFilter: 'filters.names.participantsFilter',
  exhibitionTypeFilter: 'filters.names.exhibitionTypeFilter',
  retailPriceFilter: 'filters.names.retailPrice',
  hasVerifiedRepresentativeFilter: 'filters.names.hasVerifiedRepresentative',
  hasWebsiteFilter: 'filters.names.website',
  hasEmailFilter: 'filters.names.email',
  hasPhoneFilter: 'filters.names.phone',
  onlyLatestVintageFilter: 'filters.names.onlyLatestVintage',
  hasBottleImageFilter: 'filters.names.packshot',
  hasFrontLabelImageFilter: 'filters.names.frontLabel',
  hasBackLabelImageFilter: 'filters.names.backLabel',
}
const databaseFiltersKey = () => {
  const currentPage = window.location.pathname.split('/')[3]
  const countryFilter = {
    [databaseTableNames.producers]: 'country',
    [databaseTableNames.producersDuplicates]: 'country',
    [databaseTableNames.brands]: 'tradeNameCountry',
    [databaseTableNames.wines]: 'country',
    [databaseTableNames.geoIndication]: 'country',
    default: 'country',
  }

  const wwlRatingFilter = {
    [databaseTableNames.producers]: 'wines.rating',
    [databaseTableNames.producersDuplicates]: 'wines.rating',
    [databaseTableNames.wines]: 'rating',
    default: 'wines.rating',
  }

  const specialClassificationFilter = {
    [databaseTableNames.producers]: 'wines.specialClassification',
    [databaseTableNames.producersDuplicates]: 'wines.specialClassification',
    [databaseTableNames.wines]: 'specialClassification',
    default: 'specialClassification',
  }

  return {
    countryFilter: countryFilter[currentPage] ?? countryFilter.default,
    usersFilter: 'contributorUsername',
    sourceFilter: 'source',
    creationDateFilter: 'createdAt',
    lastUpdateFilter: 'updatedAt',
    checkedByFilter: 'checkedBy',
    invalidRecordFilter: 'invalidRecord',
    registrationFilter: 'hasRegistration',
    producerFilter: 'tradeName',
    producerWWLFilter: 'hasVerifiedRepresentative',
    euGITypeFilter: 'euGIType',
    nationalGITypeFilter: 'nationalGIType',
    wineRegionEUFilter: 'wineRegionEU',
    geoIndicationFilter: 'geoIndication',
    wineTypeFilter: 'wineType',
    wineColourFilter: 'wineColor',
    sweetnessTypeFilter: 'sweetnessLevel',
    vintageFilter: 'vintage',
    bottleSizeFilter: 'bottleSize',
    packageTypeFilter: 'packageType',
    grapesFilter: 'grapeAndMonocepage',
    certificatesFilter: 'certification',
    alcoholLevelFilter: 'alcoholOnLabel',
    cellarDoorPriceFilter: 'cellarDoorPrice',
    wwlRatingFilter: wwlRatingFilter[currentPage] ?? wwlRatingFilter.default,
    brandFilter: 'brand',
    monocepageFilter: 'grapeAndMonocepage.monocepage',
    legalEntityFilter: 'hasLegalEntity',
    synonymsFilter: 'includeSynonyms',
    havePublicReviewsFilter: 'havePublicReviewsBool',
    parentGeoIndicationFilter: 'parent',
    wineTerritoryTypeFilter: 'wineTerritoryType',
    specialClassificationFilter:
      specialClassificationFilter[currentPage] ??
      specialClassificationFilter.default,
    dateStartFilter: 'dateStart',
    dateEndFilter: 'dateEnd',
    participantsFilter: 'participants',
    exhibitionTypeFilter: 'exhibitionType',
    retailPriceFilter: 'tradeOfferLotPricePerBottle',
    hasVerifiedRepresentativeFilter: 'hasVerifiedRepresentative',
    hasWebsiteFilter: 'hasWebsite',
    hasEmailFilter: 'hasEmail',
    hasPhoneFilter: 'hasPhone',
    onlyLatestVintageFilter: 'onlyLatestVintage',
    hasBottleImageFilter: 'hasBottleImage',
    hasFrontLabelImageFilter: 'hasFrontLabelImage',
    hasBackLabelImageFilter: 'hasBackLabelImage',
  }
}
export const DATABASE_ROUTE_NAMES = {
  databaseProducers: 'accDatabaseProducers',
  databaseProducersDuplicates: 'accDatabaseProducersDuplicates',
  databaseBrands: 'accDatabaseBrands',
  databaseWines: 'accDatabaseWines',
  databaseGeoIndication: 'accDatabaseGeoIndication',
  databaseExhibitions: 'accDatabaseExhibitions',
}
export const databaseTableNames = {
  producers: 'producers',
  producersDuplicates: 'producersDuplicates',
  brands: 'brands',
  wines: 'wines',
  awards: 'awards',
  geoIndication: 'geoIndication',
  exhibitions: 'exhibitions',
}

const databaseProducersDefaultFilters = [
  databaseFiltersName.synonymsFilter,
  databaseFiltersName.countryFilter,
  databaseFiltersName.sourceFilter,
  databaseFiltersName.legalEntityFilter,
  databaseFiltersName.invalidRecordFilter,
  databaseFiltersName.hasWebsiteFilter,
  databaseFiltersName.hasEmailFilter,
  databaseFiltersName.hasPhoneFilter,
  databaseFiltersName.producerWWLFilter,
  databaseFiltersName.wineRegionEUFilter,
  databaseFiltersName.nationalGITypeFilter,
  databaseFiltersName.geoIndicationFilter,
  databaseFiltersName.specialClassificationFilter,
  databaseFiltersName.wineTypeFilter,
  databaseFiltersName.wineColourFilter,
  databaseFiltersName.sweetnessTypeFilter,
  databaseFiltersName.vintageFilter,
  databaseFiltersName.grapesFilter,
  databaseFiltersName.bottleSizeFilter,
  databaseFiltersName.packageTypeFilter,
  databaseFiltersName.alcoholLevelFilter,
  databaseFiltersName.certificatesFilter,
  databaseFiltersName.cellarDoorPriceFilter,
  databaseFiltersName.retailPriceFilter,
  databaseFiltersName.wwlRatingFilter,
  databaseFiltersName.usersFilter,
  databaseFiltersName.creationDateFilter,
  databaseFiltersName.lastUpdateFilter,
  databaseFiltersName.checkedByFilter,
]
const databaseWinesDefaultFilters = [
  databaseFiltersName.countryFilter,
  databaseFiltersName.producerFilter,
  databaseFiltersName.producerWWLFilter,
  databaseFiltersName.brandFilter,
  databaseFiltersName.wineRegionEUFilter,
  databaseFiltersName.nationalGITypeFilter,
  databaseFiltersName.geoIndicationFilter,
  databaseFiltersName.specialClassificationFilter,
  databaseFiltersName.wineTypeFilter,
  databaseFiltersName.wineColourFilter,
  databaseFiltersName.sweetnessTypeFilter,
  databaseFiltersName.vintageFilter,
  databaseFiltersName.onlyLatestVintageFilter,
  databaseFiltersName.grapesFilter,
  databaseFiltersName.hasBottleImageFilter,
  databaseFiltersName.hasFrontLabelImageFilter,
  databaseFiltersName.hasBackLabelImageFilter,
  databaseFiltersName.bottleSizeFilter,
  databaseFiltersName.packageTypeFilter,
  databaseFiltersName.alcoholLevelFilter,
  databaseFiltersName.certificatesFilter,
  databaseFiltersName.cellarDoorPriceFilter,
  databaseFiltersName.retailPriceFilter,
  databaseFiltersName.wwlRatingFilter,
  databaseFiltersName.havePublicReviewsFilter,
  databaseFiltersName.sourceFilter,
  databaseFiltersName.invalidRecordFilter,
  databaseFiltersName.usersFilter,
  databaseFiltersName.creationDateFilter,
  databaseFiltersName.lastUpdateFilter,
  databaseFiltersName.checkedByFilter,
]
const databaseBrandsDefaultFilters = [
  databaseFiltersName.usersFilter,
  databaseFiltersName.countryFilter,
  databaseFiltersName.producerFilter,
  databaseFiltersName.registrationFilter,
  databaseFiltersName.sourceFilter,
  databaseFiltersName.creationDateFilter,
  databaseFiltersName.lastUpdateFilter,
  databaseFiltersName.checkedByFilter,
  databaseFiltersName.invalidRecordFilter,
  databaseFiltersName.synonymsFilter,
]
const databaseGeoIndicationDefaultFilters = [
  databaseFiltersName.countryFilter,
  databaseFiltersName.euGITypeFilter,
  databaseFiltersName.nationalGITypeFilter,
  databaseFiltersName.wineRegionEUFilter,
  databaseFiltersName.parentGeoIndicationFilter,
  databaseFiltersName.wineTerritoryTypeFilter,
]
const databaseExhibitionsDefaultFilters = [
  databaseFiltersName.dateStartFilter,
  databaseFiltersName.dateEndFilter,
  databaseFiltersName.countryFilter,
  databaseFiltersName.participantsFilter,
  databaseFiltersName.exhibitionTypeFilter,
]
// указываются списки фильтров и их порядок в зависимости от роута
export const includedDatabaseFiltersOnRoute = {
  [DATABASE_ROUTE_NAMES.databaseProducers]: {
    [Roles.PERSON]: [
      databaseFiltersName.countryFilter,
      databaseFiltersName.wineRegionEUFilter,
      databaseFiltersName.nationalGITypeFilter,
      databaseFiltersName.geoIndicationFilter,
      databaseFiltersName.specialClassificationFilter,
      databaseFiltersName.wineTypeFilter,
      databaseFiltersName.wineColourFilter,
      databaseFiltersName.sweetnessTypeFilter,
      databaseFiltersName.vintageFilter,
      databaseFiltersName.grapesFilter,
      databaseFiltersName.alcoholLevelFilter,
      databaseFiltersName.certificatesFilter,
      databaseFiltersName.bottleSizeFilter,
      databaseFiltersName.packageTypeFilter,
      databaseFiltersName.retailPriceFilter,
      databaseFiltersName.cellarDoorPriceFilter,
      databaseFiltersName.hasVerifiedRepresentativeFilter,
      databaseFiltersName.wwlRatingFilter,
    ],
    [Roles.CONTENT]: [...databaseProducersDefaultFilters],
    [Roles.SUPERADMIN]: [...databaseProducersDefaultFilters],
    [Roles.MANAGER]: [
      ...without(
        [
          databaseFiltersName.sourceFilter,
          databaseFiltersName.checkedByFilter,
          databaseFiltersName.synonymsFilter,
        ],
        databaseProducersDefaultFilters
      ),
    ],
    default: [
      databaseFiltersName.usersFilter,
      databaseFiltersName.countryFilter,
      databaseFiltersName.creationDateFilter,
      databaseFiltersName.invalidRecordFilter,
    ],
  },
  [DATABASE_ROUTE_NAMES.databaseProducersDuplicates]: {
    [Roles.PERSON]: [databaseFiltersName.countryFilter],
    default: [databaseFiltersName.countryFilter],
  },
  [DATABASE_ROUTE_NAMES.databaseBrands]: {
    [Roles.PERSON]: [
      ...without(
        [
          databaseFiltersName.sourceFilter,
          databaseFiltersName.checkedByFilter,
          databaseFiltersName.synonymsFilter,
        ],
        databaseBrandsDefaultFilters
      ),
    ],
    [Roles.MANAGER]: [
      ...without(
        [
          databaseFiltersName.sourceFilter,
          databaseFiltersName.checkedByFilter,
          databaseFiltersName.synonymsFilter,
        ],
        databaseBrandsDefaultFilters
      ),
    ],
    [Roles.CONTENT]: [...databaseBrandsDefaultFilters],
    [Roles.SUPERADMIN]: [...databaseBrandsDefaultFilters],
    default: [
      databaseFiltersName.countryFilter,
      databaseFiltersName.producerFilter,
      databaseFiltersName.registrationFilter,
      databaseFiltersName.usersFilter,
      databaseFiltersName.sourceFilter,
      databaseFiltersName.creationDateFilter,
      databaseFiltersName.checkedByFilter,
      databaseFiltersName.invalidRecordFilter,
    ],
  },
  [DATABASE_ROUTE_NAMES.databaseWines]: {
    [Roles.PERSON]: [
      // databaseFiltersName.invalidRecordFilter,
      databaseFiltersName.countryFilter,
      databaseFiltersName.producerFilter,
      databaseFiltersName.brandFilter,
      databaseFiltersName.wineRegionEUFilter,
      databaseFiltersName.nationalGITypeFilter,
      databaseFiltersName.geoIndicationFilter,
      databaseFiltersName.specialClassificationFilter,
      databaseFiltersName.wineTypeFilter,
      databaseFiltersName.wineColourFilter,
      databaseFiltersName.sweetnessTypeFilter,
      databaseFiltersName.vintageFilter,
      databaseFiltersName.onlyLatestVintageFilter,
      databaseFiltersName.retailPriceFilter,
      databaseFiltersName.cellarDoorPriceFilter,

      databaseFiltersName.grapesFilter,
      databaseFiltersName.alcoholLevelFilter,
      databaseFiltersName.certificatesFilter,
      databaseFiltersName.bottleSizeFilter,
      databaseFiltersName.packageTypeFilter,
      databaseFiltersName.producerWWLFilter,
      databaseFiltersName.wwlRatingFilter,
      databaseFiltersName.havePublicReviewsFilter,
    ],
    [Roles.CONTENT]: [...databaseWinesDefaultFilters],
    [Roles.ADMIN]: [...databaseWinesDefaultFilters],
    [Roles.SUPERADMIN]: [...databaseWinesDefaultFilters],
    [Roles.MANAGER]: [
      ...without(
        [databaseFiltersName.sourceFilter, databaseFiltersName.checkedByFilter],
        databaseWinesDefaultFilters
      ),
    ],
    default: [
      databaseFiltersName.countryFilter,
      databaseFiltersName.usersFilter,
      databaseFiltersName.sourceFilter,
      databaseFiltersName.creationDateFilter,
      databaseFiltersName.checkedByFilter,
      databaseFiltersName.invalidRecordFilter,
      databaseFiltersName.producerFilter,
      databaseFiltersName.brandFilter,
      databaseFiltersName.euGITypeFilter,
      databaseFiltersName.nationalGITypeFilter,
      databaseFiltersName.wineRegionEUFilter,
      databaseFiltersName.geoIndicationFilter,
      databaseFiltersName.wineTypeFilter,
      databaseFiltersName.wineColourFilter,
      databaseFiltersName.sweetnessTypeFilter,
      databaseFiltersName.vintageFilter,
      databaseFiltersName.bottleSizeFilter,
      databaseFiltersName.packageTypeFilter,
      databaseFiltersName.grapesFilter,
      databaseFiltersName.certificatesFilter,
      databaseFiltersName.wwlRatingFilter,
    ],
  },
  [DATABASE_ROUTE_NAMES.databaseGeoIndication]: {
    [Roles.CONTENT]: [...databaseGeoIndicationDefaultFilters],
    [Roles.SUPERADMIN]: [...databaseGeoIndicationDefaultFilters],
    default: [],
  },
  [DATABASE_ROUTE_NAMES.databaseExhibitions]: {
    default: [...databaseExhibitionsDefaultFilters],
  },
}

// общая коллекция фильтров c их параметрами
export const getDatabaseFiltersCollection = () => [
  {
    type: filterTypes[PRICE_INTERVAL],
    title: i18n.t(databaseFiltersTitle.retailPriceFilter),
    filterName: databaseFiltersName.retailPriceFilter,
    apiFilterName: databaseFiltersKey().retailPriceFilter,
    defaultIntervalKey: 'retailPriceInterval',
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(databaseFiltersTitle.onlyLatestVintageFilter),
    filterName: databaseFiltersName.onlyLatestVintageFilter,
    apiFilterName: databaseFiltersKey().onlyLatestVintageFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.hasBottleImageFilter),
    filterName: databaseFiltersName.hasBottleImageFilter,
    apiFilterName: databaseFiltersKey().hasBottleImageFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.hasFrontLabelImageFilter),
    filterName: databaseFiltersName.hasFrontLabelImageFilter,
    apiFilterName: databaseFiltersKey().hasFrontLabelImageFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.hasBackLabelImageFilter),
    filterName: databaseFiltersName.hasBackLabelImageFilter,
    apiFilterName: databaseFiltersKey().hasBackLabelImageFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.hasVerifiedRepresentativeFilter),
    filterName: databaseFiltersName.hasVerifiedRepresentativeFilter,
    apiFilterName: databaseFiltersKey().hasVerifiedRepresentativeFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.hasWebsiteFilter),
    filterName: databaseFiltersName.hasWebsiteFilter,
    apiFilterName: databaseFiltersKey().hasWebsiteFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.hasEmailFilter),
    filterName: databaseFiltersName.hasEmailFilter,
    apiFilterName: databaseFiltersKey().hasEmailFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.hasPhoneFilter),
    filterName: databaseFiltersName.hasPhoneFilter,
    apiFilterName: databaseFiltersKey().hasPhoneFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(baseOffersFiltersTitle.wineCountryFilter),
    filterName: databaseFiltersName.countryFilter,
    apiFilterName: databaseFiltersKey().countryFilter,
  },
  {
    type: filterTypes[SEARCH_USERS],
    title: i18n.t(databaseFiltersTitle.usersFilter),
    filterName: databaseFiltersName.usersFilter,
    apiFilterName: databaseFiltersKey().usersFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(databaseFiltersTitle.creationDateFilter),
    filterName: databaseFiltersName.creationDateFilter,
    apiFilterName: databaseFiltersKey().creationDateFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(databaseFiltersTitle.lastUpdateFilter),
    filterName: databaseFiltersName.lastUpdateFilter,
    apiFilterName: databaseFiltersKey().lastUpdateFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.sourceFilter),
    filterName: databaseFiltersName.sourceFilter,
    apiFilterName: databaseFiltersKey().sourceFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.checkedByFilter),
    filterName: databaseFiltersName.checkedByFilter,
    apiFilterName: databaseFiltersKey().checkedByFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.invalidRecordFilter),
    filterName: databaseFiltersName.invalidRecordFilter,
    apiFilterName: databaseFiltersKey().invalidRecordFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.legalEntityFilter),
    filterName: databaseFiltersName.legalEntityFilter,
    apiFilterName: databaseFiltersKey().legalEntityFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.producerFilter),
    filterName: databaseFiltersName.producerFilter,
    apiFilterName: databaseFiltersKey().producerFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.registrationFilter),
    filterName: databaseFiltersName.registrationFilter,
    apiFilterName: databaseFiltersKey().registrationFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.producerWWLFilter),
    filterName: databaseFiltersName.producerWWLFilter,
    apiFilterName: databaseFiltersKey().producerWWLFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.euGITypeFilter),
    filterName: databaseFiltersName.euGITypeFilter,
    apiFilterName: databaseFiltersKey().euGITypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.nationalGITypeFilter),
    filterName: databaseFiltersName.nationalGITypeFilter,
    apiFilterName: databaseFiltersKey().nationalGITypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.wineRegionEUFilter),
    filterName: databaseFiltersName.wineRegionEUFilter,
    apiFilterName: databaseFiltersKey().wineRegionEUFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.geoIndicationFilter),
    filterName: databaseFiltersName.geoIndicationFilter,
    apiFilterName: databaseFiltersKey().geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.specialClassificationFilter),
    filterName: databaseFiltersName.specialClassificationFilter,
    apiFilterName: databaseFiltersKey().specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.wineTypeFilter),
    filterName: databaseFiltersName.wineTypeFilter,
    apiFilterName: databaseFiltersKey().wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.wineColourFilter),
    filterName: databaseFiltersName.wineColourFilter,
    apiFilterName: databaseFiltersKey().wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.sweetnessTypeFilter),
    filterName: databaseFiltersName.sweetnessTypeFilter,
    apiFilterName: databaseFiltersKey().sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.vintageFilter),
    filterName: databaseFiltersName.vintageFilter,
    apiFilterName: databaseFiltersKey().vintageFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.bottleSizeFilter),
    filterName: databaseFiltersName.bottleSizeFilter,
    apiFilterName: databaseFiltersKey().bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.packageTypeFilter),
    filterName: databaseFiltersName.packageTypeFilter,
    apiFilterName: databaseFiltersKey().packageTypeFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(databaseFiltersTitle.grapesFilter),
    filterName: databaseFiltersName.grapesFilter,
    apiFilterName: databaseFiltersKey().grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: databaseFiltersName.monocepageFilter,
    switchTitle: i18n.t(databaseFiltersTitle.monocepageFilter),
    switchApiFilterName: databaseFiltersKey().monocepageFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.certificatesFilter),
    filterName: databaseFiltersName.certificatesFilter,
    apiFilterName: databaseFiltersKey().certificatesFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(databaseFiltersTitle.alcoholLevelFilter),
    filterName: databaseFiltersName.alcoholLevelFilter,
    apiFilterName: databaseFiltersKey().alcoholLevelFilter,
    defaultIntervalKey: 'alcoholLevels',
  },
  {
    type: filterTypes[PRICE_INTERVAL],
    title: i18n.t(databaseFiltersTitle.cellarDoorPriceFilter),
    filterName: databaseFiltersName.cellarDoorPriceFilter,
    apiFilterName: databaseFiltersKey().cellarDoorPriceFilter,
    defaultIntervalKey: 'priceInterval',
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(databaseFiltersTitle.wwlRatingFilter),
    filterName: databaseFiltersName.wwlRatingFilter,
    apiFilterName: databaseFiltersKey().wwlRatingFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.brandFilter),
    filterName: databaseFiltersName.brandFilter,
    apiFilterName: databaseFiltersKey().brandFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(databaseFiltersTitle.synonymsFilter),
    filterName: databaseFiltersName.synonymsFilter,
    apiFilterName: databaseFiltersKey().synonymsFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(databaseFiltersTitle.havePublicReviewsFilter),
    filterName: databaseFiltersName.havePublicReviewsFilter,
    apiFilterName: databaseFiltersKey().havePublicReviewsFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.parentGeoIndicationFilter),
    filterName: databaseFiltersName.parentGeoIndicationFilter,
    apiFilterName: databaseFiltersKey().parentGeoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.wineTerritoryTypeFilter),
    filterName: databaseFiltersName.wineTerritoryTypeFilter,
    apiFilterName: databaseFiltersKey().wineTerritoryTypeFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(databaseFiltersTitle.dateStartFilter),
    filterName: databaseFiltersName.dateStartFilter,
    apiFilterName: databaseFiltersKey().dateStartFilter,
    maxDate: null,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(databaseFiltersTitle.dateEndFilter),
    filterName: databaseFiltersName.dateEndFilter,
    apiFilterName: databaseFiltersKey().dateEndFilter,
    maxDate: null,
  },
  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(databaseFiltersTitle.participantsFilter),
    filterName: databaseFiltersName.participantsFilter,
    apiFilterName: databaseFiltersKey().participantsFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(databaseFiltersTitle.exhibitionTypeFilter),
    filterName: databaseFiltersName.exhibitionTypeFilter,
    apiFilterName: databaseFiltersKey().exhibitionTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(databaseFiltersTitle.packageTypeFilter),
    filterName: databaseFiltersName.packageTypeFilter,
    apiFilterName: databaseFiltersKey().packageTypeFilter,
  },
]
