import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getLocaleForTermsPolitics } from 'utils/getLocaleId'

const Wrapper = styled.div`
  display: flex;
  padding-bottom: 48px;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.3px;
  color: #a0a1ad;
  margin-top: 10px;

  @media (max-width: 1280px) {
    font-size: 14px;
  }

  @media (max-width: 880px) {
    justify-content: center;
  }

  @media (max-width: 450px) {
    padding-bottom: 15px;
    font-size: 11px;
  }
`
const Link = styled.a`
  text-decoration: none;
  color: var(--color-sw-40);
`

const Footer = () => {
  const { t } = useTranslation()

  const locale = getLocaleForTermsPolitics()
  return (
    <Wrapper>
      <Link
        target='_blank'
        href={`https://worldwinelist.com/${locale}/terms-and-conditions`}
      >
        {t('login.terms', { year: new Date().getFullYear() })}
      </Link>
    </Wrapper>
  )
}

export default Footer
