import { createSlice } from '@reduxjs/toolkit'
import { mergeAll } from 'ramda'
import {
  ExhibitionInfoFragment,
  ExhibitionsDisplayInfoFragment,
} from 'api/operations'
import { WineOfferTypeCode } from 'utils/consts'
import type { OffersState } from './types'
import { WineOfferExhibition } from 'api/schemas'

const initialState: OffersState = {
  items: [],
  item: {
    id: '',
    offerId: '',
    offerType: WineOfferTypeCode.Public,
    product: null,
    pricePerLot: '',
    incoterms: null,
    minimumDeliverySize: 0,
    periodOfValidity: null,
    lot: null,
    currency: null,
    warehouse: null,
    buyer: null,
    buyerCategory: null,
    errors: {},
    contacts: {
      contactEmail: null,
      contactPhone: null,
    },
    note: null,
  },
  exhibitions: [],
  history: [],
  market: {
    isValid: true,
    territory: null,
    markers: [],
    circles: [],
    bounds: [],
    exclusive: false,
    initialData: null,
  },
  selectedItems: [],
  pageInfo: {},
  allCheckboxChecked: false,
  sorting: {
    type: null,
    name: null,
    value: null,
  },
  filter: {
    type: null,
    value: null,
  },
  analogues: null,
}

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.items = payload
    },
    setPageInfo: (state, { payload }) => {
      state.pageInfo = payload
    },
    setSelectedItems: (state, { payload }) => {
      state.selectedItems = payload
    },
    setAllCheckboxChecked: (state, { payload }) => {
      state.allCheckboxChecked = payload
    },
    setSortingValue: (state, { payload }) => {
      state.sorting = payload
    },
    setItemProduct: (state, { payload }) => {
      state.item = { ...state.item, product: payload }
    },
    setItem: (state, { payload }) => {
      state.item = { ...state.item, ...payload }
    },
    reset: () => {
      return initialState
    },
    resetExcludeFilter: state => ({
      ...initialState,
      filter: state.filter,
    }),
    resetSorting: state => {
      state.sorting = initialState.sorting
    },
    resetItem: state => {
      state.item = initialState.item
    },
    resetMarket: state => {
      state.market = initialState.market
    },
    resetExhibitions: state => {
      state.exhibitions = initialState.exhibitions
    },
    setMarket: (state, { payload }) => {
      state.market = {
        ...state.market,
        ...payload,
        initialData: payload,
      }
    },
    setItemField: (state, { payload }) => {
      state.item = { ...state.item, [payload.field]: payload.data }
    },
    itemSetFieldError: (state, { payload }) => {
      state.item = {
        ...state.item,
        errors: {
          ...state.item.errors,
          [payload.field]: payload.data,
        },
      }
    },
    itemSetFieldsError: (state, { payload }) => {
      state.item = {
        ...state.item,
        errors: payload,
      }
    },
    marketSetField: (state, { payload }) => {
      state.market = { ...state.market, [payload.field]: payload.data }
    },
    marketSetBoundCoordinates: (state, { payload }) => {
      state.market = {
        ...state.market,
        bounds: state.market.bounds.map(bound => {
          if (bound.countryId === payload.id) {
            bound.coordinates = payload.coordinates
          }
          return bound
        }),
      }
    },
    setFilter: (state, { payload }) => {
      state.filter = payload
    },
    setHistory: (state, { payload }) => {
      state.history = payload
    },
    setNumberAnalogues: (state, { payload }) => {
      state.analogues = payload
        ? mergeAll(
            state.items.map((_, index) => ({
              [_?.id]: payload[`analog${index + 1}`]?.count ?? 0,
            }))
          )
        : {}
    },
    clearHistory: state => {
      state.history = []
    },
    updateRow: (state, { payload }) => {
      state.items = state.items.map(item => {
        if (item.id !== payload.id) return item
        return { ...item, ...payload.data }
      })
      state.analogues = {
        ...state.analogues,
        [payload.data.id]: 0,
      }
    },
    setExhibitions: (
      state,
      {
        payload,
      }: { payload: { exhibitions: ExhibitionsDisplayInfoFragment[] } }
    ) => {
      state.exhibitions = payload.exhibitions
    },
    deleteExhibition: (
      state,
      { payload }: { payload: { exhibitionId: number } }
    ) => {
      state.exhibitions = state.exhibitions.filter(
        item => item.exhibition.id !== payload.exhibitionId
      )
    },
    changeStand: (
      state,
      { payload }: { payload: { exhibitionId: number; stand: string } }
    ) => {
      state.exhibitions = state.exhibitions.map(item => {
        if (item.exhibition.id === payload.exhibitionId) {
          return {
            ...item,
            exhibitionStand: payload.stand,
          }
        }
        return item
      })
    },
    selectExhibitions: (
      state,
      {
        payload: { exhibitions },
      }: {
        payload: { exhibitions: ExhibitionInfoFragment[] }
      }
    ) => {
      const oldExhibitions = state.exhibitions
      const oldSet = new Set(oldExhibitions.map(item => item?.exhibition?.id))
      const selectedSet = new Set(exhibitions.map(item => item.id))
      const newSelected = exhibitions.filter(
        exhibition => !oldSet.has(exhibition?.id)
      )
      const newExhibitions = [
        // Keep already selected
        ...oldExhibitions.filter(item => selectedSet.has(item.exhibition?.id)),
        // Add new one
        ...newSelected.map(exhibition => ({
          exhibition,
          exhibitionStand: '',
        })),
      ] as WineOfferExhibition[]
      state.exhibitions = newExhibitions
    },
    reorderExhibitions: (
      state,
      {
        payload: { sourceIndex, destinationIndex },
      }: { payload: { sourceIndex: number; destinationIndex: number } }
    ) => {
      const items = [...state.exhibitions]
      const [reorderedItem] = items.splice(sourceIndex, 1)
      items.splice(destinationIndex, 0, reorderedItem)

      state.exhibitions = items
    },
  },
})

export const { actions, reducer: offers } = offersSlice
export const {
  setItems,
  setPageInfo,
  setSelectedItems,
  setAllCheckboxChecked,
  setItemProduct,
  setItem,
  reset,
  resetExcludeFilter,
  resetItem,
  resetMarket,
  setMarket,
  setItemField,
  itemSetFieldError,
  itemSetFieldsError,
  marketSetField,
  marketSetBoundCoordinates,
  setHistory,
  setNumberAnalogues,
  clearHistory,
  updateRow,
  setExhibitions,
  deleteExhibition,
  changeStand,
  selectExhibitions,
  reorderExhibitions,
  resetExhibitions,
} = actions
