import styled from 'styled-components'
import {
  CarouselProvider,
  DotGroup,
  Slide,
  Slider as PureSlider,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { useTranslation } from 'react-i18next'
import 'components/Pages/ConfirmMail/Left/Main/Slider/Slider/index.css'

const Title = styled.div`
  font: var(--typography-56-72-bold);
  color: var(--color-white);

  @media (max-width: 1280px) {
    font-size: 40px;
    line-height: 56px;
  }

  @media (max-width: 500px) {
    font-size: 24px;
    line-height: 32px;
  }
`
const Text = styled.div`
  width: 544px;
  font-size: 28px;
  line-height: 40px;
  color: #a0a1ad;
  margin: 16px 0 40px;

  @media (max-width: 1280px) {
    width: 400px;
    font-size: 20px;
    line-height: 28px;
    margin: 8px 0 32px;
  }

  @media (max-width: 500px) {
    width: 100%;
    font-size: 13px;
    line-height: 16px;
    margin: 8px 0 16px;
  }
`
const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 472px;

  @media (max-width: 880px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

const items = [
  {
    key: 1,
    title: 'login.slider.slide1.title',
    text: 'login.slider.slide1.text',
  },
  {
    key: 2,
    title: 'login.slider.slide2.title',
    text: 'login.slider.slide2.text',
  },
  {
    key: 3,
    title: 'login.slider.slide3.title',
    text: 'login.slider.slide3.text',
  },
]

const Item = ({ title, text }) => {
  const { t } = useTranslation()

  return (
    <Block>
      <Title>{t(title)}</Title>
      <Text>{t(text)}</Text>
    </Block>
  )
}

const Slider = () => {
  return (
    <CarouselProvider
      visibleSlides={1}
      step={items.length}
      naturalSlideWidth={600}
      naturalSlideHeight={600}
      totalSlides={items.length}
    >
      <PureSlider>
        {items.map((item, index) => (
          <Slide key={index} index={index}>
            <Item {...item} />
          </Slide>
        ))}
      </PureSlider>
      <DotGroup />
    </CarouselProvider>
  )
}

export default Slider
