import styled from 'styled-components'
import Left from 'components/Pages/Login/Left'
import Cookies from 'components/Common/Cookies'
import BG from 'assets/images/auth/login-background.jpg'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'

const Wrapper = styled.div`
  height: 100%;
  overflow-y: scroll;
  background-color: var(--color-night);
  background-image: url(${BG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  min-width: 318px;
  display: flex;
  justify-content: center;
  position: relative;
`
const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  min-height: 768px;

  @media (max-width: 768px) {
    min-height: 1024px;
  }

  @media (max-width: 450px) {
    min-height: 768px;
  }
`
const Right = styled.div`
  width: 40px;
  margin-left: 32px;

  @media (max-width: 1024px) {
    margin-left: 0;
  }

  @media (max-width: 880px) {
    display: none;
  }
`

interface IUseLocation {
  isLogout: boolean
}

const Login = () => {
  const { state } = useLocation<IUseLocation>()
  const cookiePolicy = localStorage.getItem('wwl-cookie_policy') || false
  const client = useApolloClient()

  useEffect(() => {
    if (state?.isLogout) {
      client.resetStore()
    }
  }, [])

  return (
    <Wrapper>
      {!cookiePolicy && <Cookies />}
      <Container>
        <Left />
        <Right />
      </Container>
    </Wrapper>
  )
}

export default Login
