import styled from 'styled-components'

const Wrapper = styled.svg`
  fill: #a0a1ad;

  :hover {
    fill: #717284;
  }
`

const See = () => {
  return (
    <Wrapper
      width='20'
      height='14'
      viewBox='0 0 20 14'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10 0C3.67936 0 0.635287 5.65451 0 7C0.635163 8.38444 3.62507 14 10 14C13.8978 14 16.5496 11.8496 18.1588 9.88551C19.159 8.66465 19.7565 7.51575 20 7C19.3648 5.61556 16.3749 0 10 0ZM10 10.8889C7.86214 10.8889 6.12706 9.14667 6.12706 7C6.12706 4.85333 7.86214 3.11111 10 3.11111C12.1379 3.11111 13.8729 4.85333 13.8729 7C13.8729 9.14667 12.1379 10.8889 10 10.8889Z'
      />
    </Wrapper>
  )
}

export default See
