import { ChangeEvent, FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import SuccessMessage from './SuccessMessage'
import PasswordInput from './PasswordInput'
import BackToLogin from './BackToLogin'
import Button from './Button'
import { captureException } from 'utils/sentry'
import { LOGIN_PAGE_PATH } from 'utils/consts/path'
import useNotification from 'utils/notification'
import {
  FormState,
  getPasswordError,
  getRepeatPasswordError,
  isFormValid,
} from './_methods'
import { Wrapper, Container, Block } from './_styles'
import { useResetPasswordMutation } from 'api/hooks'

const Form: FC = () => {
  const { t } = useTranslation()

  const notify = useNotification()
  const location = useLocation()
  const params = queryString.parse(location.search)
  const token =
    typeof params?.resetPassword === 'string' ? params.resetPassword : ''

  const [formState, setFormState] = useState<FormState>({
    password: '',
    repeatPassword: '',
    passwordError: null,
    repeatPasswordError: null,
  })

  const isValid = isFormValid(formState)

  const [resetPassword, { data, loading }] = useResetPasswordMutation({
    onCompleted() {
      history.push(LOGIN_PAGE_PATH)
    },
    onError(error) {
      notify(error.message, 'error')
      captureException(error)
    },
  })

  const history = useHistory()
  const handleSubmit = async event => {
    event.preventDefault()

    if (data) return

    if (!isValid) {
      setFormState(prev => ({
        ...prev,
        passwordError: getPasswordError(prev),
        resetPasswordError: getRepeatPasswordError(prev),
      }))
      return
    }

    resetPassword({
      variables: {
        resetToken: token,
        newPassword: formState.password,
      },
    })
  }

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormState(prev => ({
      ...prev,
      password: event.target.value,
      passwordError: null,
      repeatPasswordError: null,
    }))
  }

  const handlePasswordBlur = () => {
    setFormState(prev => {
      const passwordError = getPasswordError(prev)
      const repeatPasswordError =
        !passwordError && prev.repeatPassword
          ? getRepeatPasswordError(prev)
          : null

      return { ...prev, passwordError, repeatPasswordError }
    })
  }

  const handleRepeatPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormState(prev => ({
      ...prev,
      repeatPassword: event.target.value,
      repeatPasswordError: null,
    }))
  }

  const handleRepeatPasswordBlur = () => {
    setFormState(prev => ({
      ...prev,
      repeatPasswordError: getRepeatPasswordError(prev),
    }))
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      <Container>
        {data && <SuccessMessage />}

        <Block $hidden={!!data}>
          <PasswordInput
            autoComplete='confirm-password'
            label={t('confirmPwd.password')}
            error={formState.passwordError}
            onChange={handlePasswordChange}
            onBlur={handlePasswordBlur}
          />

          <PasswordInput
            autoComplete='new-password'
            label={t('confirmPwd.repeatPassword')}
            error={formState.repeatPasswordError}
            onChange={handleRepeatPasswordChange}
            onBlur={handleRepeatPasswordBlur}
          />

          <BackToLogin />
        </Block>
      </Container>

      <Button disabled={!isValid} loading={loading} completed={!!data} />
    </Wrapper>
  )
}

export default Form
