import styled from 'styled-components'
import Main from 'components/Pages/Login/Left/Main'
import Header from 'components/Pages/Login/Left/Header'
import Footer from 'components/Pages/Login/Left/Footer'

export const Wrapper = styled.div`
  max-width: 1048px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 32px;

  @media (max-width: 1280px) {
    max-width: 1048px;
  }

  @media (max-width: 1024px) {
    max-width: 784px;
  }

  @media (max-width: 880px) {
    margin-left: 0;
    max-width: 440px;
  }

  @media (max-width: 600px) {
    max-width: 396px;
  }

  @media (max-width: 500px) {
    max-width: 320px;
  }

  @media (max-width: 450px) {
    max-width: 288px;
  }
`

const Left = () => {
  return (
    <Wrapper>
      <Header />
      <Main />
      <Footer />
    </Wrapper>
  )
}

export default Left
