import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { LOGIN_PAGE_PATH, SIGNUP_PAGE_PATH } from 'utils/consts/path'

const Wrapper = styled.div`
  display: flex;
  height: 72px;
  background: var(--color-white);
  box-shadow: 0 3px 5px rgba(95, 99, 104, 0.05),
    0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05);
  border-radius: 16px 16px 0 0;
  margin-bottom: 44px;
  width: 100%;

  @media (max-width: 1280px) {
    height: 48px;
  }
`
const Login = styled.div`
  width: 50%;
  height: calc(100% - 4px);
  border-bottom: 4px solid var(--color-brand-red);
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.3px;
  color: var(--color-night);
  font: var(--typography-16-20-semibold);
  cursor: pointer;

  @media (max-width: 1280px) {
    font-size: 14px;
    line-height: 22px;
  }
`
const Signup = styled(Login)`
  border-left: 1px solid #e7e7ea;
  border-bottom: none;
  color: #717284;

  :hover {
    color: var(--color-night);
  }
`

const Header = () => {
  const history = useHistory()
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Login onClick={() => history.replace(LOGIN_PAGE_PATH)}>
        {t('login.login')}
      </Login>
      <Signup onClick={() => history.replace(SIGNUP_PAGE_PATH)}>
        {t('login.signup')}
      </Signup>
    </Wrapper>
  )
}

export default Header
