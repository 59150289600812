import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  width: 304px;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  color: #717284;
  margin-bottom: 24px;

  @media (max-width: 1280px) {
    width: 256px;
    padding-top: 16px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 32px;
  }

  @media (max-width: 500px) {
    width: 240px;
  }
`
const Title = styled.span`
  cursor: pointer;

  :hover {
    color: var(--color-night);
  }
`

const Forgot = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const redirect = () => {
    history.push('/acc/forgot-password')
  }

  return (
    <Wrapper>
      <Title onClick={redirect}>{t('login.forgotPwd')}</Title>
    </Wrapper>
  )
}

export default Forgot
