import { forwardRef, HTMLAttributes, ReactNode } from 'react'
import {
  Block,
  Input as StyledInput,
  InputAfter,
  Label,
  Wrapper,
} from './_styles'

export interface InputProps extends HTMLAttributes<HTMLInputElement> {
  value?: string
  hasError?: boolean
  label?: string
  before?: ReactNode
  beforeText?: ReactNode
  after?: ReactNode
  afterText?: ReactNode
  readOnly?: boolean
  disabled?: boolean
  autoFocus?: boolean
  name?: string
  placeholder?: string
  size?: 'M'
  maxLength?: number
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    hasError, // add red border
    label,
    before, // slot, displays content at the start of input
    beforeText, // slot, displays content at the start of native input
    after, // slot, displays content at the end of input
    afterText, // slot, displays content at the end of native input
    size,
    ...inputProps // other props passed to native input
  } = props

  return (
    <Wrapper
      readOnly={inputProps.readOnly}
      disabled={inputProps.disabled}
      $size={size}
    >
      {before}
      <Block readOnly={inputProps.readOnly} disabled={inputProps.disabled}>
        {beforeText}
        <StyledInput
          spellCheck='false'
          autoComplete='off'
          placeholder=' '
          {...inputProps}
          hasError={hasError}
          hasLabel={Boolean(label)}
          ref={ref}
          $size={size}
        />
        {afterText}

        <Label htmlFor={inputProps.id} hasError={hasError}>
          {label}
        </Label>
      </Block>
      {after && <InputAfter>{after}</InputAfter>}
    </Wrapper>
  )
})

export default Input
