import Cookies from 'components/Common/Cookies'
import { lazy, Suspense } from 'react'
import { Wrapper, Container, Right } from './_styles'
const Left = lazy(() => import(/* webpackChunkName: "signup" */ './Left'))

const Signup = () => {
  const cookiePolicy = localStorage.getItem('wwl-cookie_policy') || false

  return (
    <Wrapper>
      {!cookiePolicy && <Cookies />}
      <Container>
        <Suspense fallback={null}>
          <Left />
        </Suspense>
        <Right />
      </Container>
    </Wrapper>
  )
}

export default Signup
