import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { emailMask } from 'utils/masks'
import SuccessIcon from 'components/Pages/ForgotPassword/Icons/Success'
import Notify from 'components/Common/Notify'
import { getLocaleId } from 'utils/getLocaleId'
import Email from './Email'
import Login from './Login'
import Button from './Button'
import {
  Wrapper,
  Container,
  Block,
  Info,
  Notification,
  Success,
  Icon,
  Error,
} from './_styles'
import { useResetPassword } from './_methods'

const Main = () => {
  const { t } = useTranslation()
  const localeId = getLocaleId()
  const { resetPassword, isSuccess, loading, error } = useResetPassword()

  const [email, setEmail] = useState('')
  const [fieldError, setFieldError] = useState('')
  const [formError, setFormError] = useState('')

  useEffect(() => {
    if (error) {
      const code = error?.graphQLErrors?.[0]?.extensions?.code
      if (code === 'USER_NOT_FOUND') {
        setFieldError('forgotPwd.error.userNotFound')
      } else {
        setFormError('forgotPwd.error.email') // error.message
      }
    }
  }, [error, localeId])

  const isEmailValid = email && emailMask.test(email)

  const handleEmailChange = e => {
    const { value } = e.target
    setEmail(value)
    setFieldError('')
    setFormError('')
  }

  const handleEmailBlur = () => {
    setFieldError(isEmailValid ? '' : 'forgotPwd.error.email')
  }

  const handleRequest = async () => {
    if (isEmailValid && !isSuccess) {
      resetPassword(email)
    }
  }
  const handleFormSubmit = e => {
    e.preventDefault()
    handleRequest()
  }

  return (
    <Wrapper onSubmit={handleFormSubmit}>
      <Container>
        {isSuccess && (
          <Notification>
            <Icon>
              <SuccessIcon />
            </Icon>
            <Success>{t('forgotPwd.linkSent')}</Success>
          </Notification>
        )}
        <Block isSuccess={isSuccess}>
          <Info>{t('forgotPwd.enterEmail')}</Info>
          <Email
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            value={email}
            error={t(fieldError)}
          />
          <Login />
        </Block>
      </Container>
      {!isSuccess && (
        <Button
          handleRequest={handleRequest}
          disabled={!isEmailValid}
          loading={loading}
          error={error}
          isSuccess={isSuccess}
        />
      )}

      <Error>
        {formError && <Notify text={t(formError)} color='#d00a00' />}
      </Error>
    </Wrapper>
  )
}

export default Main
