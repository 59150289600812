import i18n from 'utils/i18n'
import {
  filterTypes,
  CHECKBOX_LIST,
  DATE_INTERVAL,
  RADIO_LIST,
  SCORE,
  INTERVAL,
  PRICE_INTERVAL,
  SWITCHER,
  NUMBER,
  RHEOSTAT,
  COUNTRIES_LIST,
  offersFiltersTitle,
  offersFiltersName,
  offersFiltersKey,
  EMAIL_STATUS_LIST,
  SEARCH_USERS,
  MULTISELECT_SWITCHER,
  RRP_INTERVAL,
} from 'utils/consts'

export const ADMIN_ROUTE_NAMES = {
  adminOffers: 'accAdministrationOffers',
  adminReviews: 'accAdministrationReviews',
  adminElabels: 'accAdministrationELabels',
  adminRequests: 'accAdministrationRequests',
  potentialProducers: 'accAdministrationRequestsPotentialProducers',
  adminCompanies: 'accAdministrationCompanies',
  adminUsers: 'accAdministrationUsers',
  adminTradeOffers: 'accAdministrationRetailOffers',
  adminMerchants: 'accAdministrationEShops',
  adminPriceSources: 'accAdministrationPriceSources',
}

const ADMIN_FILTERS_KEY = {
  userFilter: 'user.username',
  updateUserUsernameFilter: 'updateUserUsername',
  producerFilter: 'wine.tradeName',
  wineCountryFilter: 'wine.country',
  productCountryFilter: 'product.wine.country',
  productBrandFilter: 'product.wine.brand',
  statusFilter: 'status',
  tradeNameFilter: 'product.wine.tradeName',
  verificationFilter: 'companyVerification',
  categoryFilter: 'companyCategory',
  stripeIdStatusFilter: 'haveELabelStripeCustomerId',
  createdAtFilter: 'createdAt',
  updatedAtFilter: 'updatedAt',
  expertQualificationFilter: 'expertQualification',
  permissionFilter: 'permission',
  expertVerificationStatusFilter: 'expertVerificationStatus',
  userStatusFilter: 'status',
  merchantFilter: 'merchant.name',
  nationalGITypeFilter: 'product.wine.nationalGIType',
  wineRegionEUFilter: 'product.wine.wineRegionEU',
  specialClassificationFilter: 'product.wine.specialClassification',
  geoIndicationFilter: 'product.wine.geoIndication',
  wineTypeFilter: 'product.wine.wineType',
  wineColourFilter: 'product.wine.wineColor',
  sweetnessTypeFilter: 'product.wine.sweetnessLevel',
  vintageFilter: 'product.wine.vintage',
  bottleSizeFilter: 'product.bottleSize',
  packageTypeFilter: 'product.packageType',
  uniqueProductURLFilter: 'isProductURLUnique',
  frontLabelFilter: 'hasFrontLabelImage',
  backLabelFilter: 'hasBackLabelImage',
  packshotFilter: 'hasBottleImage',
  eshopCountryFilter: 'merchant.country',
  alcoholLevelFilter: 'chemicalAnalysisAlcohol',
  wineLotFilter: 'wineLot',
  lotCreatedAtFilter: 'lotCreatedAt',
  isProductSetFilter: 'isProductSet',
  isMerchantSetFilter: 'isMerchantSet',
  merchantCountryFilter: 'country',
  deliveryTimeFilter: 'deliveryTime',
}
export const ADMIN_FILTERS_NAME = {
  userFilter: 'usersFilter',
  updateUserUsernameFilter: 'updateUserUsernameFilter',
  producerFilter: 'producerFilter',
  wineCountryFilter: 'wineCountryFilter',
  statusFilter: 'statusFilter',
  verificationFilter: 'verificationStatusFilter',
  categoryFilter: 'companyCategoryFilter',
  stripeIdStatusFilter: 'haveELabelStripeCustomerIdFilter',
  createdAtFilter: 'createdAtFilter',
  lotCreatedAtFilter: 'lotCreatedAtFilter',
  updatedAtFilter: 'updatedAtFilter',
  expertQualificationFilter: 'expertQualificationFilter',
  permissionFilter: 'permissionFilter',
  expertVerificationStatusFilter: 'expertVerificationStatusFilter',
  userStatusFilter: 'userStatusFilter',
  merchantFilter: 'merchantFilter',
  productBrandFilter: 'productBrandFilter',
  nationalGITypeFilter: 'nationalGITypeFilter',
  wineRegionEUFilter: 'wineRegionEUFilter',
  specialClassificationFilter: 'specialClassificationFilter',
  geoIndicationFilter: 'geoIndicationFilter',
  wineTypeFilter: 'wineTypeFilter',
  wineColourFilter: 'wineColourFilter',
  sweetnessTypeFilter: 'sweetnessTypeFilter',
  vintageFilter: 'vintageFilter',
  bottleSizeFilter: 'bottleSizeFilter',
  packageTypeFilter: 'packageTypeFilter',
  uniqueProductURLFilter: 'uniqueProductURLFilter',
  frontLabelFilter: 'frontLabelFilter',
  backLabelFilter: 'backLabelFilter',
  packshotFilter: 'packshotFilter',
  eshopCountryFilter: 'eshopCountryFilter',
  alcoholLevelFilter: 'alcoholLevelFilter',
  wineLotFilter: 'wineLotFilter',
  isProductSetFilter: 'isProductSetFilter',
  isMerchantSetFilter: 'isMerchantSetFilter',
  merchantCountryFilter: 'merchantCountryFilter',
  deliveryTimeFilter: 'deliveryTimeFilter',
}
const ADMIN_FILTERS_TITLE = {
  userFilter: 'filters.names.users',
  usernameFilter: 'filters.names.user',
  producerFilter: 'filters.names.producer',
  countryFilter: 'filters.names.country',
  wineCountryFilter: 'filters.names.wineCountryFilter',
  statusFilter: 'filters.names.statusFilter',
  verificationFilter: 'filters.names.verificationStatus',
  categoryFilter: 'filters.names.companyCategory',
  stripeIdStatusFilter: 'filters.names.stripeId',
  createdAtFilter: 'filters.names.creationDate',
  lotCreatedAtFilter: 'filters.names.lotCreatedAt',
  lastUpdateFilter: 'filters.names.lastUpdate',
  updatedAtFilter: 'filters.names.updatedAt',
  expertQualificationFilter: 'filters.names.expertQualification',
  permissionFilter: 'filters.names.permission',
  expertVerificationStatusFilter: 'filters.names.expertVerificationStatus',
  userStatusFilter: 'filters.names.userStatus',
  merchantFilter: 'filters.names.merchant',
  eshopFilter: 'filters.names.eshop',
  productBrandFilter: 'filters.names.brandFilter',
  geoIndicationFilter: 'filters.names.geoIndicationFilter',
  specialClassificationFilter: 'filters.names.specialClassificationFilter',
  nationalGITypeFilter: 'filters.names.nationalGITypeFilter',
  wineRegionEUFilter: 'filters.names.wineRegionEUFilter',
  wineTypeFilter: 'filters.names.wineTypeFilter',
  wineColourFilter: 'filters.names.wineColourFilter',
  sweetnessTypeFilter: 'filters.names.sweetnessTypeFilter',
  vintageFilter: 'filters.names.vintageFilter',
  bottleSizeFilter: 'filters.names.bottleSizeFilter',
  packageTypeFilter: 'filters.names.packageTypeFilter',
  uniqueProductURLFilter: 'filters.names.uniqueProductURL',
  frontLabelFilter: 'filters.names.frontLabel',
  backLabelFilter: 'filters.names.backLabel',
  packshotFilter: 'filters.names.packshot',
  eshopCountryFilter: 'filter.names.eshopCountry',
  alcoholLevelFilter: 'filters.names.alcoholLevelFilter',
  wineLotFilter: 'filters.names.wineLot',
  isMerchantSetFilter: 'filters.names.isMerchantSetFilter',
  isProductSetFilter: 'filters.names.isProductSetFilter',
  merchantCountryFilter: 'filters.names.eshopCountry',
  deliveryTimeFilter: 'filters.names.delivetyTime',
}

// общая коллекция фильтров c их параметрами
export const getAdministrationFiltersCollection = () => [
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(offersFiltersTitle.periodOfValidityFilter),
    filterName: offersFiltersName.periodOfValidityFilter,
    apiFilterName: offersFiltersKey().periodOfValidityFilter,
    maxDate: null,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.countryFilter),
    filterName: offersFiltersName.countryFilter,
    apiFilterName: offersFiltersKey().countryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineRegionEUFilter),
    filterName: offersFiltersName.wineRegionEUFilter,
    apiFilterName: offersFiltersKey().wineRegionEUFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
    filterName: offersFiltersName.nationalGITypeFilter,
    apiFilterName: offersFiltersKey().nationalGITypeFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.geoIndicationFilter),
    filterName: offersFiltersName.geoIndicationFilter,
    apiFilterName: offersFiltersKey().geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.specialClassificationFilter),
    filterName: offersFiltersName.specialClassificationFilter,
    apiFilterName: offersFiltersKey().specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineTypeFilter),
    filterName: offersFiltersName.wineTypeFilter,
    apiFilterName: offersFiltersKey().wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineColourFilter),
    filterName: offersFiltersName.wineColourFilter,
    apiFilterName: offersFiltersKey().wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
    filterName: offersFiltersName.sweetnessTypeFilter,
    apiFilterName: offersFiltersKey().sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.vintageFilter),
    filterName: offersFiltersName.vintageFilter,
    apiFilterName: offersFiltersKey().vintageFilter,
  },
  {
    type: filterTypes[PRICE_INTERVAL],
    title: i18n.t(offersFiltersTitle.priceFilter),
    filterName: offersFiltersName.priceFilter,
    apiFilterName: offersFiltersKey().priceFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.retailPriceFilter),
    filterName: offersFiltersName.retailPriceFilter,
    apiFilterName: offersFiltersKey().retailPriceFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.rrpFilter),
    filterName: offersFiltersName.rrpFilter,
    apiFilterName: offersFiltersKey().rrpFilter,
  },

  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(offersFiltersTitle.priceCoefFilter),
    filterName: offersFiltersName.priceCoefFilter,
    apiFilterName: offersFiltersKey().priceCoefFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(offersFiltersTitle.grapesFilter),
    filterName: offersFiltersName.grapesFilter,
    apiFilterName: offersFiltersKey().grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: offersFiltersName.monocepageFilter,
    switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
    switchApiFilterName: offersFiltersKey().monocepageFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
    filterName: offersFiltersName.alcoholLevelFilter,
    apiFilterName: offersFiltersKey().alcoholLevelFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.certificatesFilter),
    filterName: offersFiltersName.certificatesFilter,
    apiFilterName: offersFiltersKey().certificatesFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.bottleSizeFilter),
    filterName: offersFiltersName.bottleSizeFilter,
    apiFilterName: offersFiltersKey().bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.packageTypeFilter),
    filterName: offersFiltersName.packageTypeFilter,
    apiFilterName: offersFiltersKey().packageTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.distributionFilter),
    filterName: offersFiltersName.distributionFilter,
    apiFilterName: offersFiltersKey().distributionFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(offersFiltersTitle.deliveryTermsFilter),
    filterName: offersFiltersName.deliveryTermsFilter,
    apiFilterName: offersFiltersKey().deliveryTermsFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(offersFiltersTitle.countryOfShipmentFilter),
    filterName: offersFiltersName.countryOfShipmentFilter,
    apiFilterName: offersFiltersKey().countryOfShipmentFilter,
  },
  {
    type: filterTypes[NUMBER],
    title: i18n.t(offersFiltersTitle.minimalBatchFilter),
    filterName: offersFiltersName.minimalBatchFilter,
    apiFilterName: offersFiltersKey().minimalBatchFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.producerFilter),
    filterName: offersFiltersName.producerFilter,
    apiFilterName: offersFiltersKey().producerFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.brandFilter),
    filterName: offersFiltersName.brandFilter,
    apiFilterName: offersFiltersKey().brandFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.buyerCompanyUsernameFilter),
    filterName: offersFiltersName.buyerCompanyUsernameFilter,
    apiFilterName: offersFiltersKey().buyerCompanyUsernameFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.buyerCompanyCategoryFilter),
    filterName: offersFiltersName.buyerCompanyCategoryFilter,
    apiFilterName: offersFiltersKey().buyerCompanyCategoryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.buyerCompanyCountryFilter),
    filterName: offersFiltersName.buyerCompanyCountryFilter,
    apiFilterName: offersFiltersKey().buyerCompanyCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.sellerCompanyUsernameFilter),
    filterName: offersFiltersName.sellerCompanyUsernameFilter,
    apiFilterName: offersFiltersKey().sellerCompanyUsernameFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.sellerCompanyCategoryFilter),
    filterName: offersFiltersName.sellerCompanyCategoryFilter,
    apiFilterName: offersFiltersKey().sellerCompanyCategoryFilter,
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(offersFiltersTitle.wwlRatingFilter),
    filterName: offersFiltersName.wwlRatingFilter,
    apiFilterName: offersFiltersKey().wwlRatingFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.exhibitionFilter),
    filterName: offersFiltersName.exhibitionFilter,
    apiFilterName: offersFiltersKey().exhibitionFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(offersFiltersTitle.exclusiveOffersFilter),
    filterName: offersFiltersName.exclusiveOffersFilter,
    apiFilterName: offersFiltersKey().exclusiveOffersFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(offersFiltersTitle.onlyFromProducerFilter),
    filterName: offersFiltersName.onlyFromProducerFilter,
    apiFilterName: offersFiltersKey().onlyFromProducerFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(offersFiltersTitle.wineOfferTypeFilter),
    filterName: offersFiltersName.wineOfferTypeFilter,
    apiFilterName: offersFiltersKey().wineOfferTypeFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.packshotFilter),
    filterName: ADMIN_FILTERS_NAME.packshotFilter,
    apiFilterName: ADMIN_FILTERS_KEY.packshotFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.frontLabelFilter),
    filterName: ADMIN_FILTERS_NAME.frontLabelFilter,
    apiFilterName: ADMIN_FILTERS_KEY.frontLabelFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.backLabelFilter),
    filterName: ADMIN_FILTERS_NAME.backLabelFilter,
    apiFilterName: ADMIN_FILTERS_KEY.backLabelFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(offersFiltersTitle.firstPublishedAtFilter),
    filterName: offersFiltersName.firstPublishedAtFilter,
    apiFilterName: offersFiltersKey().firstPublishedAtFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(offersFiltersTitle.updatedAtFilter),
    filterName: offersFiltersName.updatedAtFilter,
    apiFilterName: offersFiltersKey().updatedAtFilter,
  },
]

export const getAdministrationElabelsFilterCollection = () => [
  {
    type: filterTypes[SEARCH_USERS],
    title: i18n.t(ADMIN_FILTERS_TITLE.userFilter),
    filterName: ADMIN_FILTERS_NAME.updateUserUsernameFilter,
    apiFilterName: ADMIN_FILTERS_KEY.updateUserUsernameFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.producerFilter),
    filterName: ADMIN_FILTERS_NAME.producerFilter,
    apiFilterName: ADMIN_FILTERS_KEY.producerFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.countryFilter),
    filterName: ADMIN_FILTERS_NAME.wineCountryFilter,
    apiFilterName: ADMIN_FILTERS_KEY.wineCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.statusFilter),
    filterName: ADMIN_FILTERS_NAME.statusFilter,
    apiFilterName: ADMIN_FILTERS_KEY.statusFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.createdAtFilter),
    filterName: ADMIN_FILTERS_NAME.createdAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.createdAtFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.lastUpdateFilter),
    filterName: ADMIN_FILTERS_NAME.updatedAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.updatedAtFilter,
  },
]

export const getAdministrationCompaniesFilterCollection = () => [
  {
    type: filterTypes[SEARCH_USERS],
    title: i18n.t(ADMIN_FILTERS_TITLE.userFilter),
    filterName: ADMIN_FILTERS_NAME.userFilter,
    apiFilterName: ADMIN_FILTERS_KEY.userFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.verificationFilter),
    filterName: ADMIN_FILTERS_NAME.verificationFilter,
    apiFilterName: ADMIN_FILTERS_KEY.verificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.categoryFilter),
    filterName: ADMIN_FILTERS_NAME.categoryFilter,
    apiFilterName: ADMIN_FILTERS_KEY.categoryFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.stripeIdStatusFilter),
    filterName: ADMIN_FILTERS_NAME.stripeIdStatusFilter,
    apiFilterName: ADMIN_FILTERS_KEY.stripeIdStatusFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.createdAtFilter),
    filterName: ADMIN_FILTERS_NAME.createdAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.createdAtFilter,
  },
]

export const getAdministrationRequestsFiltersCollection = () => [
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.stageFilter),
    filterName: offersFiltersName.stageFilter,
    apiFilterName: offersFiltersKey().stageFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(offersFiltersTitle.periodOfValidityFilter),
    filterName: offersFiltersName.periodOfValidityFilter,
    apiFilterName: offersFiltersKey().periodOfValidityFilter,
    maxDate: null,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(offersFiltersTitle.hasOffersFilter),
    filterName: offersFiltersName.hasOffersFilter,
    apiFilterName: offersFiltersKey().hasOffersFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineCountryFilter),
    filterName: offersFiltersName.countryFilter,
    apiFilterName: offersFiltersKey().countryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineRegionEUFilter),
    filterName: offersFiltersName.wineRegionEUFilter,
    apiFilterName: offersFiltersKey().wineRegionEUFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
    filterName: offersFiltersName.nationalGITypeFilter,
    apiFilterName: offersFiltersKey().nationalGITypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.geoIndicationFilter),
    filterName: offersFiltersName.geoIndicationFilter,
    apiFilterName: offersFiltersKey().geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.specialClassificationFilter),
    filterName: offersFiltersName.specialClassificationFilter,
    apiFilterName: offersFiltersKey().specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.buyerCompanyUsernameFilter),
    filterName: offersFiltersName.buyerCompanyUsernameFilter,
    apiFilterName: offersFiltersKey().buyerCompanyUsernameFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.buyerCompanyCategoryFilter),
    filterName: offersFiltersName.buyerCompanyCategoryFilter,
    apiFilterName: offersFiltersKey().buyerCompanyCategoryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.buyerCompanyCountryFilter),
    filterName: offersFiltersName.buyerCompanyCountryFilter,
    apiFilterName: offersFiltersKey().buyerCompanyCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineTypeFilter),
    filterName: offersFiltersName.wineTypeFilter,
    apiFilterName: offersFiltersKey().wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineColourFilter),
    filterName: offersFiltersName.wineColourFilter,
    apiFilterName: offersFiltersKey().wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
    filterName: offersFiltersName.sweetnessTypeFilter,
    apiFilterName: offersFiltersKey().sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.vintageFilter),
    filterName: offersFiltersName.vintageFilter,
    apiFilterName: offersFiltersKey().vintageFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(offersFiltersTitle.grapesFilter),
    filterName: offersFiltersName.grapesFilter,
    apiFilterName: offersFiltersKey().grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: offersFiltersName.monocepageFilter,
    switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
    switchApiFilterName: offersFiltersKey().monocepageFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(offersFiltersTitle.alcoholLevelFilter),
    filterName: offersFiltersName.alcoholLevelFilter,
    apiFilterName: offersFiltersKey().alcoholLevelFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.certificatesFilter),
    filterName: offersFiltersName.certificatesFilter,
    apiFilterName: offersFiltersKey().certificatesFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.bottleSizeFilter),
    filterName: offersFiltersName.bottleSizeFilter,
    apiFilterName: offersFiltersKey().bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.packageTypeFilter),
    filterName: offersFiltersName.packageTypeFilter,
    apiFilterName: offersFiltersKey().packageTypeFilter,
  },
  {
    type: filterTypes[PRICE_INTERVAL],
    title: i18n.t(offersFiltersTitle.priceFilter),
    filterName: offersFiltersName.priceFilter,
    apiFilterName: offersFiltersKey().priceFilter,
  },
  {
    type: filterTypes[NUMBER],
    title: i18n.t(offersFiltersTitle.minimalBatchFilter),
    filterName: offersFiltersName.minimalBatchFilter,
    apiFilterName: offersFiltersKey().minimalBatchFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(offersFiltersTitle.deliveryTermsFilter),
    filterName: offersFiltersName.deliveryTermsFilter,
    apiFilterName: offersFiltersKey().deliveryTermsFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(offersFiltersTitle.countryOfShipmentFilter),
    filterName: offersFiltersName.countryOfShipmentFilter,
    apiFilterName: offersFiltersKey().countryOfShipmentFilter,
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(offersFiltersTitle.wwlRatingFilter),
    filterName: offersFiltersName.wwlRatingFilter,
    apiFilterName: offersFiltersKey().wwlRatingFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.producerFilter),
    filterName: offersFiltersName.producerFilter,
    apiFilterName: offersFiltersKey().producerFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.brandFilter),
    filterName: offersFiltersName.brandFilter,
    apiFilterName: offersFiltersKey().brandFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(offersFiltersTitle.exclusiveOffersFilter),
    filterName: offersFiltersName.exclusiveOffersFilter,
    apiFilterName: offersFiltersKey().exclusiveOffersFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(offersFiltersTitle.onlyFromProducerFilter),
    filterName: offersFiltersName.onlyFromProducerFilter,
    apiFilterName: offersFiltersKey().onlyFromProducerFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(offersFiltersTitle.forCompanyOnlyFilter),
    filterName: offersFiltersName.forCompanyOnlyFilter,
    apiFilterName: offersFiltersKey().forCompanyOnlyFilter,
  },
]

export const getAdministrationRequestsPotentialProducersFiltersCollection =
  () => [
    {
      type: filterTypes[COUNTRIES_LIST],
      title: i18n.t(offersFiltersTitle.producerCountryFilter),
      filterName: offersFiltersName.producerCountryFilter,
      apiFilterName: offersFiltersKey().producerCountry,
    },
    {
      type: filterTypes[SWITCHER],
      title: i18n.t(offersFiltersTitle.withEmailFilter),
      filterName: offersFiltersName.withEmailFilter,
      apiFilterName: offersFiltersKey().withEmailFilter,
      nonFacetFilter: true,
    },
    {
      type: filterTypes[NUMBER],
      title: i18n.t(offersFiltersTitle.daysSinceLastNewsletterFilter),
      filterName: offersFiltersName.daysSinceLastNewsletterFilter,
      apiFilterName: offersFiltersKey().daysSinceLastNewsletterFilter,
      label: 'filters.days',
      nonFacetFilter: true,
    },
    {
      type: filterTypes[EMAIL_STATUS_LIST],
      title: i18n.t(offersFiltersTitle.emailStatusFilter),
      filterName: offersFiltersName.emailStatusFilter,
      apiFilterName: offersFiltersKey().emailStatus,
    },
  ]
export const getAdministrationUsersFiltersCollection = () => [
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.expertQualificationFilter),
    filterName: ADMIN_FILTERS_NAME.expertQualificationFilter,
    apiFilterName: ADMIN_FILTERS_KEY.expertQualificationFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.expertVerificationStatusFilter),
    filterName: ADMIN_FILTERS_NAME.expertVerificationStatusFilter,
    apiFilterName: ADMIN_FILTERS_KEY.expertVerificationStatusFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.userStatusFilter),
    filterName: ADMIN_FILTERS_NAME.userStatusFilter,
    apiFilterName: ADMIN_FILTERS_KEY.userStatusFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.permissionFilter),
    filterName: ADMIN_FILTERS_NAME.permissionFilter,
    apiFilterName: ADMIN_FILTERS_KEY.permissionFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.createdAtFilter),
    filterName: ADMIN_FILTERS_NAME.createdAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.createdAtFilter,
  },
]
export const getAdministrationTradeOffersFiltersCollection = () => [
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.eshopCountryFilter),
    filterName: ADMIN_FILTERS_NAME.eshopCountryFilter,
    apiFilterName: ADMIN_FILTERS_KEY.eshopCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.eshopFilter),
    filterName: ADMIN_FILTERS_NAME.merchantFilter,
    apiFilterName: ADMIN_FILTERS_KEY.merchantFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.wineCountryFilter),
    filterName: ADMIN_FILTERS_NAME.wineCountryFilter,
    apiFilterName: ADMIN_FILTERS_KEY.productCountryFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.producerFilter),
    filterName: ADMIN_FILTERS_NAME.producerFilter,
    apiFilterName: ADMIN_FILTERS_KEY.tradeNameFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.productBrandFilter),
    filterName: ADMIN_FILTERS_NAME.productBrandFilter,
    apiFilterName: ADMIN_FILTERS_KEY.productBrandFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.wineRegionEUFilter),
    filterName: ADMIN_FILTERS_NAME.wineRegionEUFilter,
    apiFilterName: ADMIN_FILTERS_KEY.wineRegionEUFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.nationalGITypeFilter),
    filterName: ADMIN_FILTERS_NAME.nationalGITypeFilter,
    apiFilterName: ADMIN_FILTERS_KEY.nationalGITypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.geoIndicationFilter),
    filterName: ADMIN_FILTERS_NAME.geoIndicationFilter,
    apiFilterName: ADMIN_FILTERS_KEY.geoIndicationFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.specialClassificationFilter),
    filterName: ADMIN_FILTERS_NAME.specialClassificationFilter,
    apiFilterName: ADMIN_FILTERS_KEY.specialClassificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.wineTypeFilter),
    filterName: ADMIN_FILTERS_NAME.wineTypeFilter,
    apiFilterName: ADMIN_FILTERS_KEY.wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.wineColourFilter),
    filterName: ADMIN_FILTERS_NAME.wineColourFilter,
    apiFilterName: ADMIN_FILTERS_KEY.wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.sweetnessTypeFilter),
    filterName: ADMIN_FILTERS_NAME.sweetnessTypeFilter,
    apiFilterName: ADMIN_FILTERS_KEY.sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.vintageFilter),
    filterName: ADMIN_FILTERS_NAME.vintageFilter,
    apiFilterName: ADMIN_FILTERS_KEY.vintageFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(offersFiltersTitle.grapesFilter),
    filterName: offersFiltersName.grapesFilter,
    apiFilterName: offersFiltersKey().grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: offersFiltersName.monocepageFilter,
    switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
    switchApiFilterName: offersFiltersKey().monocepageFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.bottleSizeFilter),
    filterName: ADMIN_FILTERS_NAME.bottleSizeFilter,
    apiFilterName: ADMIN_FILTERS_KEY.bottleSizeFilter,
  },

  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.packageTypeFilter),
    filterName: ADMIN_FILTERS_NAME.packageTypeFilter,
    apiFilterName: ADMIN_FILTERS_KEY.packageTypeFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.alcoholLevelFilter),
    filterName: offersFiltersName.alcoholLevelFilter,
    apiFilterName: ADMIN_FILTERS_KEY.alcoholLevelFilter,
    defaultIntervalKey: 'alcoholLevels',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.certificatesFilter),
    filterName: offersFiltersName.certificatesFilter,
    apiFilterName: offersFiltersKey().certificatesFilter,
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(offersFiltersTitle.wwlRatingFilter),
    filterName: offersFiltersName.wwlRatingFilter,
    apiFilterName: offersFiltersKey().wwlRatingFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.rrpFilter),
    filterName: offersFiltersName.rrpFilter,
    apiFilterName: 'product.priceAtWinery',
    defaultIntervalKey: 'priceInterval',
  },
  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(offersFiltersTitle.priceCoefFilter),
    filterName: offersFiltersName.priceCoefFilter,
    apiFilterName: offersFiltersKey().priceCoefFilter,
  },

  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.priceFilter),
    filterName: offersFiltersName.pricePerBottleFilter,
    apiFilterName: 'pricePerBottle',
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.pricePerLotFilter),
    filterName: offersFiltersName.pricePerLotFilter,
    apiFilterName: 'price',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.wineLotFilter),
    filterName: ADMIN_FILTERS_NAME.wineLotFilter,
    apiFilterName: ADMIN_FILTERS_KEY.wineLotFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.uniqueProductURLFilter),
    filterName: ADMIN_FILTERS_NAME.uniqueProductURLFilter,
    apiFilterName: ADMIN_FILTERS_KEY.uniqueProductURLFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.lotCreatedAtFilter),
    filterName: ADMIN_FILTERS_NAME.lotCreatedAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.lotCreatedAtFilter,
  },

  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.isProductSetFilter),
    filterName: ADMIN_FILTERS_NAME.isProductSetFilter,
    apiFilterName: ADMIN_FILTERS_KEY.isProductSetFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.isMerchantSetFilter),
    filterName: ADMIN_FILTERS_NAME.isMerchantSetFilter,
    apiFilterName: ADMIN_FILTERS_KEY.isMerchantSetFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.lastUpdateFilter),
    filterName: ADMIN_FILTERS_NAME.updatedAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.updatedAtFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.createdAtFilter),
    filterName: ADMIN_FILTERS_NAME.createdAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.createdAtFilter,
  },
  {
    type: filterTypes[SEARCH_USERS],
    title: i18n.t(ADMIN_FILTERS_TITLE.usernameFilter),
    filterName: ADMIN_FILTERS_NAME.updateUserUsernameFilter,
    apiFilterName: ADMIN_FILTERS_KEY.updateUserUsernameFilter,
  },
]
export const getAdministrationPriceSourcesFiltersCollection = () => [
  {
    type: filterTypes[SEARCH_USERS],
    title: i18n.t(ADMIN_FILTERS_TITLE.usernameFilter),
    filterName: ADMIN_FILTERS_NAME.updateUserUsernameFilter,
    apiFilterName: ADMIN_FILTERS_KEY.updateUserUsernameFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.merchantFilter),
    filterName: ADMIN_FILTERS_NAME.merchantFilter,
    apiFilterName: ADMIN_FILTERS_KEY.merchantFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.createdAtFilter),
    filterName: ADMIN_FILTERS_NAME.createdAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.createdAtFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.updatedAtFilter),
    filterName: ADMIN_FILTERS_NAME.updatedAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.updatedAtFilter,
  },
]
export const getAdministrationEShopsFiltersCollection = () => [
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.merchantCountryFilter),
    filterName: ADMIN_FILTERS_NAME.merchantCountryFilter,
    apiFilterName: ADMIN_FILTERS_KEY.merchantCountryFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.deliveryTimeFilter),
    filterName: ADMIN_FILTERS_NAME.deliveryTimeFilter,
    apiFilterName: ADMIN_FILTERS_KEY.deliveryTimeFilter,
  },
  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(offersFiltersTitle.quantityOfItemFilter),
    filterName: offersFiltersName.quantityOfItemFilter,
    apiFilterName: offersFiltersKey().quantityOfItemFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.priceFilter),
    filterName: offersFiltersName.pricePerBottleFilter,
    apiFilterName: 'pricePerBottle',
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.pricePerLotFilter),
    filterName: offersFiltersName.pricePerLotFilter,
    apiFilterName: 'pricePerLot',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.wineLotFilter),
    filterName: ADMIN_FILTERS_NAME.wineLotFilter,
    apiFilterName: ADMIN_FILTERS_KEY.wineLotFilter,
  },
  {
    type: filterTypes[RRP_INTERVAL],
    title: i18n.t(offersFiltersTitle.cellarDoorPriceFilter),
    filterName: offersFiltersName.cellarDoorPriceFilter,
    apiFilterName: offersFiltersKey().cellarDoorPriceFilter,
    defaultIntervalKey: 'cellarDoorPrice',
  },
  {
    type: filterTypes[RHEOSTAT],
    title: i18n.t(offersFiltersTitle.priceCoefFilter),
    filterName: offersFiltersName.priceCoefFilter,
    apiFilterName: offersFiltersKey().priceCoefFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineCountryFilter),
    filterName: offersFiltersName.countryFilter,
    apiFilterName: 'wineCountry',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.producerFilter),
    filterName: offersFiltersName.producerFilter,
    apiFilterName: 'tradeName',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.brandFilter),
    filterName: offersFiltersName.brandFilter,
    apiFilterName: 'brand',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineRegionEUFilter),
    filterName: offersFiltersName.wineRegionEUFilter,
    apiFilterName: 'wineRegionEU',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.nationalGITypeFilter),
    filterName: offersFiltersName.nationalGITypeFilter,
    apiFilterName: 'nationalGIType',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.geoIndicationFilter),
    filterName: offersFiltersName.geoIndicationFilter,
    apiFilterName: 'geoIndication',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.specialClassificationFilter),
    filterName: offersFiltersName.specialClassificationFilter,
    apiFilterName: 'specialClassification',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineTypeFilter),
    filterName: offersFiltersName.wineTypeFilter,
    apiFilterName: 'wineType',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.wineColourFilter),
    filterName: offersFiltersName.wineColourFilter,
    apiFilterName: 'wineColor',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.sweetnessTypeFilter),
    filterName: offersFiltersName.sweetnessTypeFilter,
    apiFilterName: 'sweetnessLevel',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.vintageFilter),
    filterName: offersFiltersName.vintageFilter,
    apiFilterName: 'vintage',
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(offersFiltersTitle.grapesFilter),
    filterName: offersFiltersName.grapesFilter,
    apiFilterName: offersFiltersKey().grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: offersFiltersName.monocepageFilter,
    switchTitle: i18n.t(offersFiltersTitle.monocepageFilter),
    switchApiFilterName: 'grapeAndMonocepage',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.bottleSizeFilter),
    filterName: ADMIN_FILTERS_NAME.bottleSizeFilter,
    apiFilterName: 'bottleSize',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(ADMIN_FILTERS_TITLE.packageTypeFilter),
    filterName: ADMIN_FILTERS_NAME.packageTypeFilter,
    apiFilterName: 'packageType',
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.alcoholLevelFilter),
    filterName: offersFiltersName.alcoholLevelFilter,
    apiFilterName: 'chemicalAnalysisAlcohol',
    defaultIntervalKey: 'alcoholLevels',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(offersFiltersTitle.certificatesFilter),
    filterName: offersFiltersName.certificatesFilter,
    apiFilterName: 'certification',
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(offersFiltersTitle.wwlRatingFilter),
    filterName: offersFiltersName.wwlRatingFilter,
    apiFilterName: 'wines.rating',
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.createdAtFilter),
    filterName: ADMIN_FILTERS_NAME.createdAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.createdAtFilter,
  },
  {
    type: filterTypes[DATE_INTERVAL],
    title: i18n.t(ADMIN_FILTERS_TITLE.lastUpdateFilter),
    filterName: ADMIN_FILTERS_NAME.updatedAtFilter,
    apiFilterName: ADMIN_FILTERS_KEY.updatedAtFilter,
  },
]
