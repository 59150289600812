import i18n from 'utils/i18n'
import {
  filterTypes,
  CHECKBOX_LIST,
  SWITCHER,
  SCORE,
  RADIO_LIST,
  INTERVAL,
  MULTISELECT_SWITCHER,
} from 'utils/consts'

export const myWinesFiltersName = {
  offerTypeFilter: 'offerTypeFilter',
  usersFilter: 'usersFilter',
  countryFilter: 'countryFilter',
  creationDateFilter: 'creationDateFilter',
  lastUpdateFilter: 'lastUpdateFilter',
  brandFilter: 'brandFilter',
  nationalGITypeFilter: 'nationalGITypeFilter',
  wineRegionEUFilter: 'wineRegionEUFilter',
  geoIndicationFilter: 'geoIndicationFilter',
  specialClassificationFilter: 'specialClassificationFilter',
  wineTypeFilter: 'wineTypeFilter',
  wineColourFilter: 'wineColourFilter',
  sweetnessTypeFilter: 'sweetnessTypeFilter',
  vintageFilter: 'vintageFilter',
  bottleSizeFilter: 'bottleSizeFilter',
  packageTypeFilter: 'packageTypeFilter',
  grapesFilter: 'grapesFilter',
  monocepageFilter: 'monocepageFilter',
  certificationFilter: 'certificationFilter',
  wwlRatingFilter: 'wwlRatingFilter',
  rrpFilter: 'rrpFilter',
  reviewsFilter: 'reviewsFilter',
  invalidRecordFilter: 'invalidRecordFilter',
  showLatestVintagesFilter: 'showLatestVintagesFilter',
  alcoholLevelFilter: 'alcoholLevelFilter',
}

const myWinesFiltersTitle = {
  offerTypeFilter: 'filters.names.offers',
  usersFilter: 'filters.names.users',
  countryFilter: 'filters.names.country',
  creationDateFilter: 'filters.names.creationDate',
  lastUpdateFilter: 'filters.names.lastUpdate',
  brandFilter: 'filters.names.brandFilter',
  nationalGITypeFilter: 'filters.names.nationalGITypeFilter',
  wineRegionEUFilter: 'filters.names.wineRegionEUFilter',
  geoIndicationFilter: 'filters.names.geoIndicationFilter',
  specialClassificationFilter: 'filters.names.specialClassificationFilter',
  wineTypeFilter: 'filters.names.wineTypeFilter',
  wineColourFilter: 'filters.names.wineColourFilter',
  sweetnessTypeFilter: 'filters.names.sweetnessTypeFilter',
  vintageFilter: 'filters.names.vintageFilter',
  bottleSizeFilter: 'filters.names.bottleSizeFilter',
  packageTypeFilter: 'filters.names.packageTypeFilter',
  grapesFilter: 'filters.names.grapesFilter',
  monocepageFilter: 'filters.names.monocepageFilter',
  certificationFilter: 'filters.names.certificatesFilter',
  wwlRatingFilter: 'filters.names.wwlRatingFilter',
  rrpFilter: 'filters.names.rrp',
  reviewsFilter: 'filters.names.havePublicReviewsFilters',
  invalidRecordFilter: 'filters.names.invalidRecord',
  showLatestVintagesFilter: 'filters.names.showLatestVintages',
  alcoholLevelFilter: 'filters.names.alcoholLevelFilter',
}

const myWinesFiltersKey = {
  offerTypeFilter: 'sellerHasOffer',
  usersFilter: 'contributorUsername',
  countryFilter: 'wine.country',
  creationDateFilter: 'createdAt',
  lastUpdateFilter: 'updatedAt',
  brandFilter: 'wine.brand',
  nationalGITypeFilter: 'wine.nationalGIType',
  showLatestVintagesFilter: 'onlyLatestVintage',
  alcoholLevelFilter: 'alcoholOnLabel',
  wineRegionEUFilter: 'wine.wineRegionEU',
  geoIndicationFilter: 'wine.geoIndication',
  specialClassificationFilter: 'wine.specialClassification',
  wineTypeFilter: 'wine.wineType',
  wineColourFilter: 'wine.wineColor',
  sweetnessTypeFilter: 'wine.sweetnessLevel',
  vintageFilter: 'wine.vintage',
  bottleSizeFilter: 'bottleSize',
  packageTypeFilter: 'packageType',
  grapesFilter: 'grapeAndMonocepage',
  monocepageFilter: 'wine.monocepage',
  certificationFilter: 'wine.certification',
  wwlRatingFilter: 'wine.rating',
  rrpFilter: 'priceAtWinery',
  reviewsFilter: 'havePublicReviewsBool',
  invalidRecordFilter: 'invalidRecord',
}

export const MY_WINES_ROUTE_NAME = 'accMyWinesWines'
export const myWinesTableName = 'accWines'

// общая коллекция фильтров c их параметрами
export const getMyWinesFiltersCollection = () => [
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(myWinesFiltersTitle.offerTypeFilter),
    filterName: myWinesFiltersName.offerTypeFilter,
    apiFilterName: myWinesFiltersKey.offerTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.brandFilter),
    filterName: myWinesFiltersName.brandFilter,
    apiFilterName: myWinesFiltersKey.brandFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.wineTypeFilter),
    filterName: myWinesFiltersName.wineTypeFilter,
    apiFilterName: myWinesFiltersKey.wineTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.wineColourFilter),
    filterName: myWinesFiltersName.wineColourFilter,
    apiFilterName: myWinesFiltersKey.wineColourFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.sweetnessTypeFilter),
    filterName: myWinesFiltersName.sweetnessTypeFilter,
    apiFilterName: myWinesFiltersKey.sweetnessTypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.vintageFilter),
    filterName: myWinesFiltersName.vintageFilter,
    apiFilterName: myWinesFiltersKey.vintageFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(myWinesFiltersTitle.showLatestVintagesFilter),
    filterName: myWinesFiltersName.showLatestVintagesFilter,
    apiFilterName: myWinesFiltersKey.showLatestVintagesFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.nationalGITypeFilter),
    filterName: myWinesFiltersName.nationalGITypeFilter,
    apiFilterName: myWinesFiltersKey.nationalGITypeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.geoIndicationFilter),
    filterName: myWinesFiltersName.geoIndicationFilter,
    apiFilterName: myWinesFiltersKey.geoIndicationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.specialClassificationFilter),
    filterName: myWinesFiltersName.specialClassificationFilter,
    apiFilterName: myWinesFiltersKey.specialClassificationFilter,
  },
  {
    type: filterTypes[MULTISELECT_SWITCHER],
    title: i18n.t(myWinesFiltersTitle.grapesFilter),
    filterName: myWinesFiltersName.grapesFilter,
    apiFilterName: myWinesFiltersKey.grapesFilter,
    switchType: filterTypes[SWITCHER],
    switchFilterName: myWinesFiltersName.monocepageFilter,
    switchTitle: i18n.t(myWinesFiltersTitle.monocepageFilter),
    switchApiFilterName: myWinesFiltersKey.monocepageFilter,
  },
  {
    type: filterTypes[INTERVAL],
    title: i18n.t(myWinesFiltersTitle.alcoholLevelFilter),
    filterName: myWinesFiltersName.alcoholLevelFilter,
    apiFilterName: myWinesFiltersKey.alcoholLevelFilter,
    defaultIntervalKey: 'alcoholLevels',
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.certificationFilter),
    filterName: myWinesFiltersName.certificationFilter,
    apiFilterName: myWinesFiltersKey.certificationFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.bottleSizeFilter),
    filterName: myWinesFiltersName.bottleSizeFilter,
    apiFilterName: myWinesFiltersKey.bottleSizeFilter,
  },
  {
    type: filterTypes[CHECKBOX_LIST],
    title: i18n.t(myWinesFiltersTitle.packageTypeFilter),
    filterName: myWinesFiltersName.packageTypeFilter,
    apiFilterName: myWinesFiltersKey.packageTypeFilter,
  },
  {
    type: filterTypes[SCORE],
    title: i18n.t(myWinesFiltersTitle.wwlRatingFilter),
    filterName: myWinesFiltersName.wwlRatingFilter,
    apiFilterName: myWinesFiltersKey.wwlRatingFilter,
  },
  {
    type: filterTypes[SWITCHER],
    title: i18n.t(myWinesFiltersTitle.reviewsFilter),
    filterName: myWinesFiltersName.reviewsFilter,
    apiFilterName: myWinesFiltersKey.reviewsFilter,
  },
  {
    type: filterTypes[RADIO_LIST],
    title: i18n.t(myWinesFiltersTitle.invalidRecordFilter),
    filterName: myWinesFiltersName.invalidRecordFilter,
    apiFilterName: myWinesFiltersKey.invalidRecordFilter,
  },
]
