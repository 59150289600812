import styled from 'styled-components'

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`
export const Container = styled.div`
  position: relative;
`
export const Block = styled.div<{ $hidden: boolean }>`
  opacity: ${props => (props.$hidden ? 0 : 1)};
`
