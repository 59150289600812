import { useState, useEffect, FC } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Loader from 'components/Common/Loader'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Container = styled(PrimaryButtonFlexable48)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  height: 72px;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  border-radius: 8px;
  padding: 0 24px;
  font: var(--typography-16-20-semibold);
  letter-spacing: 0.5px;
  transition: background-color 0.2s;

  @media (max-width: 1280px) {
    height: 48px;
  }
`
const Title = styled.div`
  font-size: 16px;

  @media (max-width: 1280px) {
    font-size: 14px;
  }
`

interface ButtonProps {
  disabled: boolean
  loading: boolean
  completed: boolean
}

const Button: FC<ButtonProps> = props => {
  const { disabled, loading, completed } = props
  const { t } = useTranslation()

  const [size, setSize] = useState(1920)

  useEffect(() => {
    const r = window.innerWidth || 1920
    setSize(r)
  }, [])

  return (
    <Wrapper>
      {/* @ts-ignore */}
      <Container fakeDisabled={disabled} type='submit'>
        {completed ? (
          <Title>{t('confirmPwd.login')}</Title>
        ) : loading ? (
          <Loader color='#fff' size={size <= 1280 ? '23px' : '30px'} />
        ) : (
          <Title>{t('confirmPwd.confirm')}</Title>
        )}
      </Container>
    </Wrapper>
  )
}

export default Button
