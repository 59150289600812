import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { LOGIN_PAGE_PATH, SIGNUP_PAGE_PATH } from 'utils/consts/path'
import { pushPrevLocation, popPrevLocation } from 'store/location'

export const LocationWatcher = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(pushPrevLocation(location.pathname))

    return history.listen((location, action) => {
      switch (action) {
        case 'PUSH':
          dispatch(pushPrevLocation(location.pathname))
          break

        case 'POP':
          dispatch(popPrevLocation(location.pathname))
          break

        case 'REPLACE':
          dispatch({
            type: 'LOCATION_REPLACE_PREV',
            payload: location.pathname,
          })
          break
      }
    })
  }, [])

  return null
}

const usePrevLocation = () => {
  return useSelector(state => {
    const arr = state.location.prev
    return arr.length > 1 ? arr[arr.length - 2] : null
  })
}

const pathsToExclude = [LOGIN_PAGE_PATH, SIGNUP_PAGE_PATH]

export const useGoBack = ({ fallbackUrl } = {}) => {
  const history = useHistory()
  const prevLocation = usePrevLocation()

  return () => {
    if (prevLocation && !pathsToExclude.includes(prevLocation)) {
      history.goBack()
      return
    }
    if (fallbackUrl) history.push(fallbackUrl)
  }
}

