import {
  Children,
  cloneElement,
  useEffect,
  useState,
  isValidElement,
} from 'react'
import styled from 'styled-components'
import { styledScrollbar } from 'components/Common/GlobalStyles'
import LoadMoreObserver from '../LoadMoreObserver'

const Wrapper = styled.div`
  z-index: var(--z-index-select);
  position: absolute;
  top: 38px;
  padding: 24px 0 10px;
  width: 100%;
  background: var(--color-white);
  border-left: 1px solid #e2e2ea;
  border-right: 1px solid #e2e2ea;
  border-bottom: 1px solid #e2e2ea;
  box-sizing: border-box;
  box-shadow: 0 3px 5px rgba(95, 99, 104, 0.05),
    0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05);
  border-radius: 0 0 8px 8px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 264px;
  overflow: hidden auto;

  ${styledScrollbar};

  /*
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 8px;
  }
   */
`

const ITEMS_PER_PAGE = 50

const List = props => {
  const { onSelect, children, activeItemIndex, highlight } = props

  const [page, setPage] = useState(0)

  useEffect(() => {
    setPage(0)
  }, [children])

  const items = Children.toArray(children)

  return (
    <Wrapper role='listbox'>
      <Content>
        {items.slice(0, ITEMS_PER_PAGE * (page + 1)).map((item, index) => {
          if (!item || !isValidElement(item)) return null

          const selectable =
            typeof item.props.selectable === 'boolean'
              ? item.props.selectable
              : !item.props.disabled

          return cloneElement(item, {
            onMouseDown: () => (selectable ? onSelect(item.props.value) : null),
            isActive: selectable && activeItemIndex === index,
            highlight,
          })
        })}

        {page < items.length / ITEMS_PER_PAGE && (
          <LoadMoreObserver onLoadMore={() => setPage(prev => prev + 1)} />
        )}
      </Content>
    </Wrapper>
  )
}

export default List
