import * as React from 'react'
import { useEffect } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { useConfirmMailMutation } from 'api/hooks'
import { setTokens } from 'utils/token'
import Error from '../Error'
import Button from '../Button'
import Success from './Success'
import LoadingIndicator from '../LoadingIndicator'
import { Block, Wrapper } from '../_styles'
import { captureException } from 'utils/sentry'

const Confirm = () => {
  const [confirmMail, { data, loading, error }] = useConfirmMailMutation()

  const location = useLocation()

  React.useEffect(() => {
    const params = queryString.parse(location.search)
    const token = params?.confirmToken

    if (token) {
      confirmMail({
        variables: { token },
        context: { ignoreAccessErrors: true },
      }).catch(captureException)
    }
  }, [])

  useEffect(() => {
    if (!data) return
    const { token, refreshToken } = data?.confirmMail ?? {}
    setTokens(token, refreshToken)
  }, [data])

  return (
    <Wrapper>
      <Block>
        {loading && <LoadingIndicator />}
        {error && <Error message={error.message} />}
        {data && <Success />}
      </Block>
      <Button disabled={!!error} />
    </Wrapper>
  )
}

export default Confirm
