const Success = () => (
  <svg
    width='80'
    height='80'
    viewBox='0 0 80 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.67036 80H72.3302C76.5693 79.9967 80 76.6139 80 72.4456V32.0393C80 31.4075 79.7147 30.8092 79.2255 30.4047L68.5221 21.5034V5.99005C68.5221 4.40226 67.8801 2.87805 66.7388 1.75491C65.5975 0.63178 64.0485 0 62.4352 0H17.9927C14.6299 0 11.9057 2.68081 11.9057 5.99005V21.1791L0.77445 30.4047C0.285321 30.8091 0 31.4075 0 32.0393V72.4456C0 76.6139 3.43069 79.9967 7.66981 80H7.67036ZM72.3241 75.7214H7.67036C5.83272 75.718 4.34837 74.2506 4.34837 72.4456V36.1211L29.1309 53.3724L11.5397 66.619C10.5784 67.3276 10.3848 68.668 11.1049 69.614C11.825 70.5567 13.187 70.7505 14.1483 70.0419L32.8544 55.9558L38.9413 60.2344C39.692 60.7592 40.7008 60.7592 41.4549 60.2344L47.5418 55.9558L66.2548 70.0154C67.2127 70.724 68.5782 70.5335 69.2983 69.5875C70.0184 68.6415 69.8214 67.3011 68.8635 66.5925L51.3062 53.3724L75.6539 36.4283V72.4559C75.6505 74.2576 74.1662 75.7183 72.3319 75.7216L72.3241 75.7214ZM74.4369 32.0291L68.5232 36.1439V27.1023L74.4369 32.0291ZM17.9935 4.28828H62.443C62.9049 4.28828 63.3466 4.46879 63.6726 4.78968C63.9987 5.11058 64.1822 5.54512 64.1822 5.99976V39.1487L40.2179 55.8456L16.2605 39.1487V5.98949C16.2673 5.05016 17.0384 4.29139 17.9929 4.28806L17.9935 4.28828ZM11.9066 36.1468L5.75175 31.8682L11.9066 26.7339V36.1468Z'
      fill='#3B42A5'
    />
    <path
      d='M48.8232 21.0007C48.2736 21.0194 47.7521 21.2379 47.3708 21.6124C43.324 25.4462 40.2342 28.6479 36.478 32.273L32.4774 29.0773C32.0499 28.7356 31.4937 28.5687 30.9342 28.6123C30.3747 28.6576 29.8581 28.9119 29.4967 29.3176C29.1353 29.7248 28.9587 30.2491 29.0082 30.7781C29.0577 31.307 29.3267 31.7955 29.7575 32.1356L35.2501 36.5296C36.0886 37.1974 37.3264 37.149 38.1021 36.4172C42.6526 32.1059 45.8924 28.6622 50.355 24.4336C50.9821 23.861 51.1736 22.9825 50.8336 22.2226C50.4952 21.4611 49.6964 20.9774 48.8234 21.0008L48.8232 21.0007Z'
      fill='#00A042'
    />
  </svg>
)

export default Success
