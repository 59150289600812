import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Select from 'components/Common/LanguageSwitcher/Select'
import { LOCALES_TO_EXCLUDE } from 'utils/getLocaleId'

const Lang = styled.div`
  cursor: pointer;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: var(--color-white);
`

const LanguageSwitcher = () => {
  const [lang, setLang] = useState(() => {
    const ls = localStorage.getItem('wwl-lng') || ''
    const lang = ls.substring(0, 2).toUpperCase()
    if (LOCALES_TO_EXCLUDE.includes(lang.toLowerCase())) return 'EN'
    return lang || 'EN'
  })

  const [opened, setOpened] = useState(false)
  const [, setClickedOutside] = useState(false)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  const selectLangRef = useRef()

  const handleClickInside = () => {
    setClickedOutside(false)
  }

  const handleClickOutside = e => {
    if (selectLangRef.current && !selectLangRef.current.contains(e.target)) {
      setClickedOutside(true)
      setOpened(false)
    }
  }

  const chooseLang = v => {
    setLang(v)
    setOpened(false)
  }

  return (
    <div ref={selectLangRef} onClick={handleClickInside}>
      <Lang onClick={() => setOpened(prevState => !prevState)}>{lang}</Lang>
      {opened && <Select chooseLang={chooseLang} />}
    </div>
  )
}

export default LanguageSwitcher
