import styled from 'styled-components'
import Main from './Main'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Block = styled.div`
  width: 304px;

  @media (max-width: 1280px) {
    width: 256px;
  }

  @media (max-width: 500px) {
    width: 240px;
  }
`

const Form = () => {
  return (
    <Wrapper>
      <Block>
        <Main />
      </Block>
    </Wrapper>
  )
}

export default Form
