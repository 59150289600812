import { createSlice } from '@reduxjs/toolkit'
import { ProductSort } from 'api/schemas'
import { WineRatingStore } from './types'

export const DEFAULT_SORT = ProductSort.AppellationRankDesc

const initialState: WineRatingStore = {
  page: 1,
  sort: DEFAULT_SORT,
  loading: false,
  items: [],
  pageInfo: null,
}

const wineRatingSlice = createSlice({
  name: 'wineRating',
  initialState,
  reducers: {
    setPage: (state, { payload }) => {
      state.page = payload
    },
    setSort: (state, { payload }) => {
      state.sort = payload
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    },
    setItems: (state, { payload }) => {
      state.items = payload
    },
    setPageInfo: (state, { payload }) => {
      state.pageInfo = payload
    },
    reset: () => {
      return initialState
    },
  },
})

export const { actions, reducer: wineRating } = wineRatingSlice
export const { setPage, setSort, setLoading, setItems, setPageInfo, reset } =
  actions
