import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import SubItem from './SubItem'
import { getCompanyVerified } from 'store/me'
import { ButtonMenuItem } from '../../_styles'
import { MenuItem } from '../../../../_methods'

const Wrapper = styled.div`
  display: flex;
  position: relative;
`

const SubItemsPopup = styled.div`
  display: flex;
  width: 256px;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  position: fixed;
  transform: translate(72px, 0);
  z-index: calc(var(--z-index-tooltip) + 1);
  padding: 8px;
  background: var(--grey-100, #121432);
`

const Title = styled.div`
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  color: var(--grey-10, #e7e7ea);
  font: var(--typography-14-20-semibold);
`

interface MenuItemWithSubItemsProps {
  isActive: boolean
  items: MenuItem['items']
  title: string
  children: ReactNode
  disabled?: boolean
}

const MenuItemWithSubItems: FC<MenuItemWithSubItemsProps> = props => {
  const { children, isActive, items, disabled, title } = props

  const { t } = useTranslation()
  const isVerified = useSelector(getCompanyVerified)

  const [opened, setOpened] = useState(false)
  const toggle = () => setOpened(prev => !prev)

  const location = useLocation()
  const currentPath = location.pathname

  const wrapperRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const listener = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpened(false)
      }
    }
    document.addEventListener('click', listener)
    return () => {
      document.removeEventListener('click', listener)
    }
  }, [])

  return (
    <Wrapper ref={wrapperRef}>
      <ButtonMenuItem onClick={toggle} $active={isActive} $disabled={disabled}>
        {children}
      </ButtonMenuItem>

      {!disabled && opened && (
        <SubItemsPopup onClick={toggle}>
          <Title>{t(title)}</Title>

          {items!.map(item => (
            <SubItem
              key={item.id}
              title={item.title}
              link={item.link}
              badge={item.badge}
              active={!!matchPath(currentPath, { path: item.link })}
              disabled={!isVerified && item.forVerifiedOnly}
            />
          ))}
        </SubItemsPopup>
      )}
    </Wrapper>
  )
}

export default MenuItemWithSubItems
