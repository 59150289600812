import styled from 'styled-components'
import Form from 'components/Pages/Login/Left/Main/Form'
import Slider from 'components/Pages/Login/Left/Main/Slider'

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-height: 648px) {
    height: auto;
  }

  @media (max-width: 880px) {
    height: auto;
    flex-direction: column;
    justify-content: normal;
  }
`
const Block = styled.div`
  width: 400px;
  min-width: 400px;
  background-color: var(--color-white);
  border-radius: 16px;

  @media (max-width: 1280px) {
    width: 320px;
    min-width: 320px;
  }

  @media (max-width: 880px) {
    margin: 64px 0 220px;
  }

  @media (max-width: 500px) {
    margin: 32px 0 84px;
    width: 288px;
    min-width: 288px;
  }
`

const Main = () => {
  return (
    <Wrapper>
      <Slider />
      <Block>
        <Form />
      </Block>
    </Wrapper>
  )
}

export default Main
