import Dialog from 'components/Common/Modals/Dialog'
import styled from 'styled-components'
import { PrimaryButtonFlexable48 } from 'components/Common/MasterComponents/Buttons/PrimaryButtonFlexable48'

const Main = styled.div`
  width: 100%;
  max-width: 416px;
`
const Footer = styled.div`
  display: flex;
`
const Description = styled.div`
  color: var(--color-night-sky);
  font: var(--typography-18-24-semibold);
  margin-bottom: 32px;
`
const OkButton = styled(PrimaryButtonFlexable48)`
  width: 100%;
`

const BannedModal = ({ closeModal }) => {
  return (
    <Dialog.Wrapper>
      <Main>
        <Dialog.Header title='Blocked' onClose={closeModal} />
        <Dialog.Content padding='32px'>
          <Description>
            Access is denied. You have violated the rules of the system. Contact
            your administrator
          </Description>
          <Footer>
            <OkButton onClick={closeModal}>Ok</OkButton>
          </Footer>
        </Dialog.Content>
      </Main>
    </Dialog.Wrapper>
  )
}

export default BannedModal
