import styled from 'styled-components'
import LogoIcon from 'components/Common/Icons/Logo/Big'
import LanguageSwitcher from 'components/Common/LanguageSwitcher'

const Wrapper = styled.div`
  display: flex;
  margin-top: 48px;

  @media (max-width: 880px) {
    justify-content: center;
    margin-top: 63px;
  }

  @media (max-width: 500px) {
    justify-content: space-between;
    margin-top: 32px;
  }
`
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const Icon = styled.div`
  svg {
    height: 40px;
    width: 219px;

    @media (max-width: 1280px) {
      height: 32px;
      width: 175px;
    }
  }
`
const Block = styled.div`
  margin-top: 5px;

  @media (max-width: 880px) {
    display: flex;
    justify-content: flex-end;
  }
`

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <Icon>
          <a href='https://worldwinelist.com/'>
            <LogoIcon />
          </a>
        </Icon>
        <Block>
          <LanguageSwitcher />
        </Block>
      </Container>
    </Wrapper>
  )
}

export default Header
